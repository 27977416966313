import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-premier-membership-plus',
  templateUrl: './premier-membership-plus.component.html',
  styleUrls: ['./premier-membership-plus.component.scss']
})
export class PremierMembershipPlusComponent {
  @Input() panelCollapsed = true;
  @Input() expired = false;
  @Output() telehealth = new EventEmitter();
  @Output() prayer = new EventEmitter();
  @Output() reactivate = new EventEmitter();
  @Output() cancelSubscription = new EventEmitter();
}

import { Routes } from "@angular/router";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { LoginComponent } from "./components/login/login.component";

export const appRoutes: Routes = [
  { path: "", redirectTo: "main", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  {
    path: "main",
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
  },
  {
    path: "wellness-store",
    loadChildren: () =>
      import("./modules/wellness-store/wellness-store.module").then(
        (m) => m.WellnessStoreModule
      ),
  },
  { path: "**", redirectTo: "main", pathMatch: "full" },
];

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "stateName",
})
export class StateNamePipe implements PipeTransform {
  transform(stateCode: string, states: any): string {
    if (states && states.length) {
      if (states.find(({ value }) => value === stateCode)) {
        return states.find(({ value }) => value === stateCode)["label"];
      }
    }
    return "";
  }
}

import { AuthService } from './../../../services/auth.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessageService } from "primeng/api";

import { AffiliateService } from "./../../../services/affiliate.service";
import { IMediaLibraryVideo } from "../../../classes/media-library-video";

@Component({
  selector: "app-media-library",
  templateUrl: "./media-library.component.html",
  styleUrls: ["./media-library.component.scss"],
})
export class MediaLibraryComponent implements OnInit, OnDestroy {
  @Input() panelCollapsed: boolean;
  @Output() panelToggled = new EventEmitter(null);

  private destroy$ = new Subject();

  public memberId: string;
  public dialogsVisibility = {
    emailDialogIsVisible: false,
    embedCodeDialogIsVisible: false,
    viewBannerDialogVisible: false,
  };
  public emailDialogVideo: IMediaLibraryVideo;
  public embedVideoCode: string;
  public isLoading: false;
  public videos: IMediaLibraryVideo[] = [];

  constructor(
    private affiliateService: AffiliateService,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.memberId = this.authService.getCurrentUser().memberId;
    this.getVideos();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get videos from API using service
  private getVideos(): void {
    this.affiliateService
      .getVideos(this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (videos) => {
          this.videos = videos;
        },
        ({ error: { error_description } }) => {
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      );
  }

  public onMessageSent({ severity, summary }): void {
    this.messageService.add({
      severity,
      summary,
    });
  }

  /**
   * Sets dialogs visibility to false
   * @param dialogVisibilityProperty
   */
  public onDialogHide(dialogVisibilityProperty: string): void {
    this.dialogsVisibility[dialogVisibilityProperty] = false;

    this.emailDialogVideo = null;
    this.embedVideoCode = null;
  }

  /**
   * Parse embed code string and open embed code dialog
   */
  public openEmbedVideoCodeDialog(videoUrl: string): void {
    this.embedVideoCode = `<iframe src="${videoUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
    this.dialogsVisibility.embedCodeDialogIsVisible = true;
  }

  /**
   * Open email video message dialog
   */
  public openEmailVideoMessageDialog(video: IMediaLibraryVideo): void {
    this.emailDialogVideo = JSON.parse(JSON.stringify(video));
    this.dialogsVisibility.emailDialogIsVisible = true;
  }
}

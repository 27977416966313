import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";

import { WizardHelperService } from "../../../../../../services/wizard-helper.service";
import { ArpOrderService } from "../../../../../../services/arp-order.service";
import { Product } from "../../../../../../classes/arp-order";

@Component({
  selector: "app-available-products",
  templateUrl: "./available-products.component.html",
  styleUrls: ["./available-products.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AvailableProductsComponent implements OnInit, OnDestroy {
  @Input() countryId: number;
  @Input() selectedProducts: Product[];
  @Output() onSelectProduct = new EventEmitter<Product>();
  @Output() onUnSelectProduct = new EventEmitter<Product>();

  public filteredProductsItems: { product: Product; checked: boolean }[];
  public isLoading: boolean;
  public queryName = "";
  public spinnerText: string;
  
  private products: Product[];
  private sub: Subscription;
  private carouselItemList: { product: Product; checked: boolean }[];

  constructor(
    private arpOrderService: ArpOrderService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // Timeout is needed for carousel takes full width of parent
    setTimeout(() => {
      this.getAvailableProducts();
      this.unCheckedToggleBtn();
    }, 100);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // forming product list for carousel component
  private createCarouselItems(): void {
    this.carouselItemList = this.products.map((product) => {
      return { product, checked: false };
    });
    this.selectedProducts.map((selectedProduct) => {
      const selectedProductItem = this.carouselItemList.find(
        (item) => item.product.productId === selectedProduct.productId
      );
      if (selectedProductItem) {
        selectedProductItem.checked = true;
      }
    });
    this.filteredProductsItems = this.carouselItemList.slice();
  }

  // get product list for some country from service
  private getAvailableProducts(): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    if (this.arpOrderService.availableProducts) {
      this.products = this.arpOrderService.availableProducts;
      this.isLoading = false;
      this.spinnerText = null;
      this.createCarouselItems();
    } else {
      this.arpOrderService.getAvailableProducts(this.countryId).subscribe(
        (response) => {
          this.products = response;
          this.isLoading = false;
          this.spinnerText = null;
          this.createCarouselItems();
        },
        ({ error: { error_description } }) => {
          this.isLoading = false;
          this.spinnerText = null;
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      );
    }
  }

  // filter product list by product name
  public searchProduct(): void {
    this.filteredProductsItems = this.carouselItemList.filter((item) => {
      return (
        item.product.productName
          .toLowerCase()
          .indexOf(this.queryName.toLowerCase()) > -1 ||
        item.product.itemNumber
          .toLowerCase()
          .indexOf(this.queryName.toLowerCase()) > -1 ||
        item.product.csvKeywords
          .toString()
          .indexOf(this.queryName.toLowerCase()) > -1 ||
        item.product.price.toString().indexOf(this.queryName.toLowerCase()) > -1
      );
    });
  }

  // handle checked/unchecked product item and pass it to parent component
  public handleChange(event, product: Product): void {
    if (event.checked) {
      this.onSelectProduct.emit(product);
    } else {
      this.onUnSelectProduct.emit(product);
    }
  }

  // handle checked/unchecked product item in available product list
  private unCheckedToggleBtn(): void {
    this.sub = this.wizardHelperService.removedProductSubject.subscribe(
      (product: Product) => {
        const removedProduct = this.carouselItemList.find(
          (item) => item.product.productId === product.productId
        );
        removedProduct.checked = false;
      }
    );
  }
}

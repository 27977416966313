import { AuthService } from './../../../services/auth.service';
import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";

import { ConfigService } from "../../../services/config.service";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { LoginService } from "../../../services/login.service";
import { showError } from "../../../helpers/form-helper";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  private isCheckingSsoIframe = false;
  private iframeOrigin: string;
  private iframeSrc: SafeResourceUrl;
  private iframeSrcLoginForm: SafeResourceUrl;
  private submitErrorDescription: string;
  private submitted = false;
  private visibleLoginFormIframe = false;

  public isLoading = false;
  public loginForm: FormGroup;
  public showError: Function = showError;
  public spinnerText: string;

  /**
   * Handle message event from child iframes
   * @param event
   */
  @HostListener("window:message", ["$event"])
  onWindowMessage(event) {
    if (event.origin === this.iframeOrigin) {
      this.handleGotUserData(event);
    }
  }

  constructor(
    private fb: FormBuilder,
    private configService: ConfigService,
    private gaService: GoogleAnalyticsService,
    private loginService: LoginService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {
    // this.iframeOrigin = this.configService.getConfiguration().ssoDomain;
  }

  ngOnInit() {
    // this.checkUserIsLogged();
    // this.createIframeSrc();
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  private checkUserIsLogged(): void {
    this.spinnerText = "Processing your request...";
    this.isLoading = true;
    // Check token in Single Sign On
    this.isCheckingSsoIframe = true;
    // Timeout is needed for carousel takes full width of parent
    setTimeout(() => {
      if (this.isCheckingSsoIframe) {
        this.visibleLoginFormIframe = true;
        this.isLoading = false;
      }
    }, 3000);
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  private createIframeSrc(): void {
    this.iframeSrcLoginForm = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeOrigin + `/Auth/Login?memberId=&returnUrl=${location.origin}`
    );
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeOrigin + `/auth/loggedin?parentURL=${location.origin}`
    );
  }

  /**
   * Get event data
   * @param messageEvent
   */
  private getEventData(messageEvent: MessageEvent): any {
    try {
      return JSON.parse(messageEvent.data);
    } catch (e) {
      return messageEvent.data;
    }
  }

  /**
   * Send entered email to login service
   */
  public setEnteredEmail(email: string): void {
    this.loginService.setEnteredEmail(email);
  }

  public onForgotPasswordClick(): void {
    this.gaService.sendGoogleAnalyticsEvent("forgotPassword");
  }

  // get billing and shipping address of current user to saving
  private getAdditionalUserDataAndRedirect(userInfo): void {
    const currentUser = JSON.parse(JSON.stringify(userInfo));
    this.authService.setCurrentUser(currentUser);
    this.isLoading = false;
    this.spinnerText = null;
    this.router.navigate(["/main"]);
  }

  /**
   * Send username and password to login service
   */
  public onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.valid && this.loginForm.dirty) {
      this.isLoading = true;
      this.spinnerText = "Processing your request...";
      this.loginService.login(this.loginForm.value).subscribe(
        (user) => {
          this.isLoading = false;
          this.spinnerText = null;
          this.router.navigate(["/main"]);
        },
        ({ error: { error_description } }) => {
          this.isLoading = false;
          this.spinnerText = null;
          this.submitErrorDescription = error_description;
        }
      );
    }
  }

  private handleGotUserData(messageEvent: MessageEvent): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    const eventData = this.getEventData(messageEvent);

    if (typeof eventData === "string") {
      // eventData is token
      this.isCheckingSsoIframe = false;
      this.authService.getCurrentUserByToken(eventData).subscribe(
        (response) => {
          this.getAdditionalUserDataAndRedirect(response);
        },
        ({ error: { error_description }, status }) => {
          if ([401, 403].includes(status)) {
            this.visibleLoginFormIframe = true;
            this.isLoading = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
            this.isLoading = false;
            this.spinnerText = null;
          }
        }
      );
    } else if (eventData.memberId) {
      // eventData is user info object
      this.isCheckingSsoIframe = false;
      this.getAdditionalUserDataAndRedirect(eventData);
    } else {
      this.isLoading = false;
      this.visibleLoginFormIframe = true;
    }
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { showError } from "../../../helpers/form-helper";
import { UserInfo } from "../../../classes/user-info";

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
})
export class PaymentMethodComponent implements OnInit {
  @Input() currentUser: UserInfo = new UserInfo();
  @Input() panelCollapsed: boolean;

  public isEditing = false;
  public isLoading = false;
  public paymentMethodEditForm: FormGroup;
  public showError: Function = showError;
  public submitted = false;
  // TODO: remove this code add getting credit types from servise
  public creditCardTypesOptions = [
    { value: "American Express", label: "American Express" },
    { value: "Visa", label: "Visa" },
    { value: "Master Card", label: "Master Card" },
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  /**
   * Build model driven paymentMethodEditForm form
   */
  private buildForm(): void {
    this.paymentMethodEditForm = this.fb.group({
      creditCardType: ["", Validators.required],
      creditCardNumber: ["", Validators.required],
      creditCardExpiryDay: ["", Validators.required],
      address1: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      country: ["", Validators.required],
    });
  }

  /**
   * Update contact information
   */
  public onSubmit(): void {
    this.submitted = true;
    if (this.paymentMethodEditForm.valid && this.paymentMethodEditForm.dirty) {
      this.isLoading = true;
      this.isEditing = false;
      this.isLoading = false;
    }
  }
}

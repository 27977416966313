import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";

import { WizardHelperService } from "../../../../../services/wizard-helper.service";
import { ArpOrderService } from "../../../../../services/arp-order.service";
import { ArpOrder, Product } from "../../../../../classes/arp-order";

@Component({
  selector: "app-arp-order-products",
  templateUrl: "./arp-order-products.component.html",
  styleUrls: ["./arp-order-products.component.scss"],
})
export class ArpOrderProductsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup;
  @Input() memberId: number;
  @Input() mode = "view";
  @Input() order: ArpOrder;
  @Input() wizardStepName: string;

  @Output() onUnSelectProduct = new EventEmitter<Product>();
  @Output() onAddProductClick = new EventEmitter<ArpOrder>();
  @Output() onTotalChanged = new EventEmitter<number>();

  public isLoading: boolean;
  public spinnerText: string;
  public subtotal = 0;

  private sub: Subscription;
  private _estimatedTotal = 0;

  constructor(
    private arpOrderService: ArpOrderService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  public get estimatedTotal(): number {
    this._estimatedTotal =
      this.order &&
      (this.order.estimatedTax || this.order.estimatedTax === 0) &&
      this.order.estimatedShipping
        ? this.order.estimatedTax + this.order.estimatedShipping + this.subtotal
        : 0;
    this.onTotalChanged.emit(this._estimatedTotal);
    return this._estimatedTotal;
  }

  ngOnInit() {
    this.initMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (
        changes[propName] &&
        changes.hasOwnProperty(propName) &&
        (propName === "form" || propName === "order")
      ) {
        this.initMode();
      }
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private countSubtotalAndTotal(products: Product[]): void {
    this.subtotal = 0;

    products.forEach((product) => {
      const totalPrice = product.quantity * product.price;
      this.subtotal += totalPrice;
    });
  }

  private initMode(): void {
    // Add/Edit mode
    if (
      this.mode === "add" ||
      (this.mode === "edit" &&
        (!this.order.orderId || this.form.value.orderId === this.order.orderId))
    ) {
      this.countSubtotalAndTotal(this.form.controls.products.value);

      this.sub = this.form.controls.products.valueChanges.subscribe((value) => {
        this.countSubtotalAndTotal(value);
      });
      // View mode
    } else if (this.mode === "view" && this.order) {
      this.countSubtotalAndTotal(this.order.products);
    }
  }

  /**
   * Remove product from order products list
   */
  public removeProduct(product: Product, index: number): void {
    if (this.mode === "add") {
      this.onUnSelectProduct.emit(product);
      this.wizardHelperService.setDeletedProductFromSelectList(product);
    } else if (this.mode === "edit") {
      this.form.controls.products["removeAt"](index);
      // need to call change detection in child datatable
      this.form.controls.products["controls"] = this.form.controls.products[
        "controls"
      ].slice();
    }
  }

  // for skipping an item in an order
  public skipOnNextOrder(
    productId: number,
    index: number,
    orderId: number
  ): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.arpOrderService
      .skipItemInArpOrder(this.memberId, orderId, productId)
      .subscribe(
        (response) => {
          this.arpOrderService.getArpOrders(this.memberId).subscribe(
            (orders) => {
              this.wizardHelperService.setOrderList(orders.arpOrders);
              this.isLoading = false;
              this.spinnerText = null;
              this.messageService.add({
                severity: "success",
                summary: "Product will be skipped in next order",
              });
            },
            ({ error: { error_description } }) => {
              this.isLoading = false;
              this.spinnerText = null;
              this.messageService.add({
                severity: "error",
                summary: error_description,
              });
            }
          );
        },
        ({ error: { error_description } }) => {
          this.isLoading = false;
          this.spinnerText = null;
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      );
  }
}

import { catchError } from "rxjs/operators";
import { LogOutService } from "./log-out.service";
import { bool } from "aws-sdk/clients/signer";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, interval, Observable, Subject } from "rxjs";
import { HttpService } from "./http.service";
import { UserInfo } from "../classes/user-info";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { WellnessStoreState } from "../modules/wellness-store/store/wellness-store.reducer";
import { clearStore } from "../modules/wellness-store/store/wellness-store.actions";
import { Router } from "@angular/router";

@Injectable()
export class AuthService {
  private loginChangedSubject = new Subject();
  public loginChanged$ = this.loginChangedSubject.asObservable();

  private ocentureSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  sso: any;
  private user: any;
  private token: any;

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private logoutService: LogOutService,
    private store: Store<{ wellnessStore: WellnessStoreState }>,
    private router: Router
  ) {
    this.startListenToEvents();
    this.getCurrentUser();
    this.getToken();
  }

  startListenToEvents() {
    window.addEventListener("sso:ready", (event) => {
      this.sso = window["sso"];
      console.log(this.sso);
      if (this.sso) {
        window.addEventListener("sso:login", (event) => {
          this.user = this.sso.profile;

          if (this.user) {
            this.token = this.sso.token;
            this.setCurrentUser(this.user);
            this.loginChangedSubject.next();
            console.log(this.user);
            console.log(this.token);
          }

          this.router.navigate(["/main"]);
        });

        window.addEventListener("sso:logout", () => {
          this.user = null;
          this.loginChangedSubject.next();
          localStorage.clear();
        });

        window.addEventListener("session:timeout", () => {
          this.logOut();
        });
      }
    });
  }

  get ocentureStream(): Observable<UserInfo> {
    return this.ocentureSubject.asObservable();
  }

  openLoginModal(redirectURL?) {
    this.sso.openLoginModal(redirectURL);
  }

  closeLoginModal() {
    this.sso.closeLoginModal();
  }

  logOut() {
    this.sso?.logout();
    localStorage.clear();
    this.setCurrentUser(null);
    localStorage.removeItem("user");
    this.logoutService.setLogOutSubject(false);
    this.store.dispatch(clearStore());
    this.router.navigate(["/login"]);
  }

  isLoggedIn() {
    return this.sso?.isLoggedIn;
  }

  getCurrentUser() {
    this.user = window["sso"]?.profile;

    if (this.user) {
      return this.user;
    }

    this.user = JSON.parse(localStorage.getItem("user"));
    return this.user;
  }

  setCurrentUser(user: UserInfo): void {
    this.user = JSON.parse(JSON.stringify(user));
    const token = this.getToken();
    this.user = { ...this.user, token };
    localStorage.setItem("user", JSON.stringify(this.user));
    this.setOcenture(this.user);
  }

  setOcenture(user: UserInfo): void {
    const token = this.getToken();
    if (!token) return;
    forkJoin([
      this.httpService.post(
        "/PMP/Status",
        "trivita_ocenture",
        { CustID: user?.memberId },
        token
      ),
      this.httpService.post(
        "/PMP/AcceptedPNP",
        "trivita_ocenture",
        { CustID: user?.memberId, Accepted: 2 },
        token
      ),
    ]).subscribe((response) => {
      localStorage.setItem("ocenture", JSON.stringify(response));
      this.ocentureSubject.next(response);
    });
  }

  getToken() {
    if (!this.token) {
      this.token = window["sso"]?.token;
    } else if (!this.token) {
      this.token = localStorage.getItem("token");
    }
    return this.token;
  }

  getCurrentUserByToken(token: string): Observable<UserInfo> {
    return this.httpService.get(`profile`, "global", token);
  }

  checkUserStores(): Observable<any> {
    return this.httpClient.get(
      `wellness/api/Customer/${this.getCurrentUser().memberId}/Store`
    );
  }

  /**
   * Gets from API vita points quantity from profile info
   */

  updateUserInfo(): Observable<any> {
    const userToken = this.getToken();
    const memberId = this.getCurrentUser().memberId;
    return this.httpService.get(`profile/${memberId}`, "cart", userToken);
  }
  public cancelPMPsubscription(): Observable<any> {
    return this.httpService.post(
      "PMP/Cancel",
      "trivita_ocenture",
      { CustID: this.user?.memberId },
      this.user?.token
    );
  }

  resetPassword() {
    this.sso.resetPassword();
  }
}

import { AuthService } from "./../../services/auth.service";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Store } from "@ngrx/store";

import {
  getHeaderConfiguration,
  HeaderConfiguration,
} from "./header-configuration";
import {
  fetchSiteSettings,
  setCurrentStoreID,
  showSpinner,
} from "../../modules/wellness-store/store/wellness-store.actions";
import { WellnessStoreState } from "../../modules/wellness-store/store/wellness-store.reducer";
import { CustomerStores } from "../../modules/wellness-store/interfaces/store.interface";
import { LocalStorage } from "../../helpers/local-storage.decorator";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @LocalStorage() user;
  @LocalStorage() storeID;
  @LocalStorage() isCCExpired;

  public configuration: HeaderConfiguration[];
  public activeSegment: HeaderConfiguration;
  public customerStores: CustomerStores[];
  public currentStoreID: number;
  public path: string;
  public show = false;
  public closed = true;

  private destroy$ = new Subject();

  // currentUser;

  constructor(
    public authService: AuthService,
    private router: Router,
    private store: Store<{ wellnessStore: WellnessStoreState }>,
    private ref: ChangeDetectorRef
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects)
      )
      .subscribe((path: string) => {
        this.path = path;

        if (!this.configuration) {
          return;
        }
        if (this.path === "/wellness-store/mode") {
          this.activeSegment =
            this.configuration[this.configuration.length - 1];
          this.configuration.forEach((item) => (item.active = false));
          this.activeSegment.active = true;
        } else {
          this.configuration.forEach((primary: HeaderConfiguration) => {
            if (this.path.includes(primary.value)) {
              primary.active = true;
              this.activeSegment = primary;
            } else {
              primary.active = false;
            }

            if (primary.secondary && primary.active) {
              primary.secondary.forEach((secondary: HeaderConfiguration) => {
                secondary.active = this.path.includes(secondary.value);
              });
            }
          });
        }

        setTimeout(() => {
          this.ref.detectChanges();
        }, 100);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (!this.user) {
      this.user = this.authService.getCurrentUser();
    }
    this.store
      .select((store) => store.wellnessStore?.customerStores)
      .pipe(
        takeUntil(this.destroy$),
        filter((customerStores) => !!customerStores)
      )
      .subscribe((customerStores) => {
        this.customerStores = customerStores;
      });

    this.store
      .select((store) => store.wellnessStore?.currentStoreID)
      .pipe(
        takeUntil(this.destroy$),
        filter((currentStoreID) => !!currentStoreID)
      )
      .subscribe((currentStoreID) => {
        this.currentStoreID = currentStoreID;
      });

    this.store
      .select((store) => store.wellnessStore)
      .pipe(
        tap(() => {
          if (!this.configuration && this.user) {
            this.authService.checkUserStores().subscribe((stores) => {
              // this http request "checkUserStores" failed you will not have menus populated in this header.
              this.configuration = getHeaderConfiguration(
                this.user.isAffiliate,
                this.user.accountFlags.includes(9995),
                !!stores?.length
              );
              setTimeout(() => {
                this.ref.detectChanges();
              }, 100);
            });
          }
        }),
        filter((state) => state && !Object.is(state.admin, null)),
        map((state) => state.admin),
        distinctUntilChanged((prev, curr) => prev === curr)
      )
      .subscribe((admin) => {
        this.configuration = getHeaderConfiguration(
          this.user.isAffiliate,
          this.user.accountFlags.includes(9995),
          false,
          admin
        );
        setTimeout(() => {
          this.ref.detectChanges();
        }, 100);
      });
  }

  public changeModule(item, mobile?: boolean): void {
    const path = item.secondary
      ? `${item.value}${item.secondary[0].value}`
      : `${item.value}`;

    this.router.navigateByUrl(path);

    if (mobile) {
      setTimeout(() => (this.closed = true), 1000);
    }
  }

  public changeSubModule(path: string): void {
    this.router.navigateByUrl(this.activeSegment.value + path);
  }

  public changeModuleAndSubmodule(segment, path): void {
    this.activeSegment = segment;
    this.router.navigateByUrl(this.activeSegment.value + path);

    setTimeout(() => (this.closed = true), 1000);
  }

  public selectStore({ value: currentStoreID }): void {
    const currentStore = this.customerStores.filter(
      ({ customer: { ID } }) => currentStoreID === ID
    );

    this.storeID = currentStoreID;
    this.isCCExpired =
      new Date() > new Date(currentStore[0].customer?.ExpirationDate);

    if (this.isCCExpired) {
      this.router.navigateByUrl("main/subscription");
    }

    this.store.dispatch(showSpinner());
    this.store.dispatch(setCurrentStoreID({ currentStoreID }));
    this.store.dispatch(fetchSiteSettings());
  }

  public switchMobileMenu(): void {
    this.closed = !this.closed;

    if (this.closed) {
      document.getElementById("chat-widget-container").style.display = "block";
    } else {
      document.getElementById("chat-widget-container").style.display = "none";
    }
  }

  logOut() {
    this.authService.logOut();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessageService } from "primeng/api";

import { AffiliateService } from "../../../../services/affiliate.service";
import { Patterns } from "../../../../helpers/helper";
import { IMediaLibraryVideo } from "../../../../classes/media-library-video";
import { IEmailMessage } from "../../../../classes/email-message";
import { showError } from "../../../../helpers/form-helper";

@Component({
  selector: "app-email-video-message-dialog",
  templateUrl: "./email-video-message-dialog.component.html",
  styleUrls: ["./email-video-message-dialog.component.scss"],
})
export class EmailVideoMessageDialogComponent implements OnInit {
  @Input() memberId: string;
  @Input() emailDialogIsVisible: boolean;
  @Input() emailDialogVideo: IMediaLibraryVideo;
  @Output() onDialogHide = new EventEmitter();
  @Output() onMessageSent = new EventEmitter();

  private destroy$ = new Subject();

  public isLoading = false;
  public messageForm: FormGroup;
  public messageFormIsSubmitted = false;
  public showError: Function = showError;

  constructor(
    private affiliateService: AffiliateService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.buildMessageForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Build model driven Message form
   */
  private buildMessageForm(): void {
    this.messageForm = this.fb.group({
      memberId: [this.memberId],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      subject: [
        this.emailDialogVideo.Title,
        [Validators.required, Validators.maxLength(250)],
      ],
      message: ["", [Validators.required, Validators.maxLength(500)]],
      emailAddress: [
        "",
        [Validators.required, Validators.pattern(Patterns.email)],
      ],
      TemplateID: [this.emailDialogVideo.TemplateID],
      VideoID: [this.emailDialogVideo.VideoID],
    });
  }

  /**
   * Send message to API
   * @param {Object} emailMessage
   */
  public onSubmit(emailMessage: IEmailMessage): void {
    this.messageFormIsSubmitted = true;

    if (this.messageForm.valid) {
      this.isLoading = true;
      this.affiliateService
        .sendEmailMessage(emailMessage)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response) => {
            this.isLoading = false;
            this.onDialogHide.emit("emailDialogIsVisible");
            this.onMessageSent.emit(null);
          },
          ({ error: { error_description } }) => {
            this.isLoading = false;

            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        );
    }
  }
}

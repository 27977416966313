import { AuthService } from './../../../services/auth.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { SelectItem } from "primeng/primeng";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Patterns } from "./../../../helpers/helper";
import { Payment } from "../../../classes/payment";
import { showError } from "../../../helpers/form-helper";
import { AffiliateService } from "../../../services/affiliate.service";

@Component({
  selector: "app-compensation-information",
  templateUrl: "./compensation-information.component.html",
  styleUrls: ["./compensation-information.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CompensationInformationComponent implements OnInit, OnDestroy {
  @Input() panelCollapsed: boolean;
  @Output() panelToggled = new EventEmitter(null);

  private memberId: string;

  public accountTypeOptions: SelectItem[] = [];
  public calendarInputYearRange: string;
  public filteredPayments: Payment[] = [];
  public forms = {
    business: null,
    banking: null,
  };
  public info = {
    business: null,
    banking: null,
  };
  public isEditing = {
    business: false,
    banking: false,
  };
  public isLoading = false;
  public payments: Payment[] = [];
  public paymentsDatesRange: Date[];
  public showError: Function = showError;
  public submitted = {
    business: false,
    banking: false,
  };
  public businessTypeOptions: SelectItem[] = [];
  public taxIdMask: (string | RegExp)[];

  private destroy$ = new Subject();

  constructor(
    private affiliateService: AffiliateService,
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.calendarInputYearRange = `${
      new Date().getFullYear() - 20
    }:${new Date().getFullYear()}`;
    this.setPaymentsDatesRange();
  }

  ngOnInit(): void {
    this.memberId = this.authService.getCurrentUser().memberId;
    this.getPayments();
    this.getBankingAndBusinessInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Build model driven Banking form
   */
  private buildBankingForm(): void {
    this.forms.banking = this.fb.group({
      accountNumber: [this.info.banking.accountNumber, Validators.required],
      accountType: [this.info.banking.accountType, Validators.required],
      usRoutingNumber: [
        this.info.banking.usRoutingNumber,
        this.info.banking.usRoutingNumber ? Validators.required : [],
      ],
      caInstitution: [
        this.info.banking.caInstitution,
        this.info.banking.caInstitution ? Validators.required : [],
      ],
      caTransit: [
        this.info.banking.caTransit,
        this.info.banking.caTransit ? Validators.required : [],
      ],
    });
  }

  /**
   * Build model driven Business form
   */
  private buildBusinessForm(): void {
    this.forms.business = this.fb.group({
      businessType: [this.info.business.businessType, Validators.required],
      companyName: [this.info.business.companyName, Validators.required],
      taxId: [this.info.business.taxId, Validators.required],
    });
    const validatorName =
      this.info.business.businessType === "Sole Proprietorship"
        ? "taxIdForSoleProprietorship"
        : "taxIdForAnotherProprietorship";

    this.taxIdMask = Patterns[validatorName].mask;
  }

  /**
   * Return sum of values of some object property in array
   * @param array
   * @param fieldName
   */
  public countSum(array: any[], fieldName: string): number {
    return array
      .map((item) => item[fieldName])
      .reduce((total, number) => total + (number ? number : 0));
  }

  /**
   * Retrieve banking and business info from API using service
   */
  private getBankingAndBusinessInfo(): void {
    this.affiliateService
      .getInfo("banking", this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.info.banking = response.banking;
          for (const option of this.info.banking.accountTypeOptions) {
            this.accountTypeOptions.push({
              label: option,
              value: option,
            });
          }
          this.buildBankingForm();
        },
        ({ error: { error_description }, status }) => {
          if ([401, 403].includes(status)) {
            this.authService.logOut();
          } else {
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        }
      );

    this.affiliateService
      .getInfo("business", this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.info.business = response.companyInfo;
          for (const key of Object.keys(
            this.info.business.businessTypeOptions
          )) {
            this.businessTypeOptions.push({
              label: this.info.business.businessTypeOptions[key],
              value: this.info.business.businessTypeOptions[key],
            });
          }
          this.buildBusinessForm();
        },
        ({ error: { error_description }, status }) => {
          if ([401, 403].includes(status)) {
            this.authService.logOut();
          } else {
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        }
      );
  }

  /**
   * Gets filtered by dates range payments from API
   */
  private getPayments(): void {
    this.affiliateService
      .getPayments(this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.payments = response.payments;
          this.filteredPayments = this.filter(
            this.payments,
            "paymentDate",
            this.paymentsDatesRange
          );
        },
        ({ error: { error_description }, status }) => {
          if ([401, 403].includes(status)) {
            this.authService.logOut();
          } else {
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        }
      );
  }

  /**
   * Filter array by date range
   * @param array
   * @param fieldName
   * @param searchQuery
   */
  private filter(array: any[], fieldName: string, datesRange: Date[]): any[] {
    return array.filter((payment) => {
      const amountDate = new Date(payment[fieldName]);

      // Start and end dates are selected
      if (datesRange && datesRange[0] && datesRange[1]) {
        const c = new Date(datesRange[1].getTime()); // clone the date to not muck the original value
        c.setDate(c.getDate() + 1); // add a day

        return amountDate >= datesRange[0] && amountDate < c;
      }
      // Only start date is selected
      if (datesRange && datesRange[0]) {
        return (
          amountDate.setHours(0, 0, 0, 0) === datesRange[0].setHours(0, 0, 0, 0)
        );
      }

      // Dates aren't selected
      return true;
    });
  }

  /**
   * Post banking or business info
   * @param {string} formName - 'businessSubmit' or 'banking' string
   */
  public onSubmit(formName: string): void {
    this.submitted[formName] = true;

    if (this.forms[formName].valid) {
      this.isLoading = true;
      this.affiliateService
        .saveInfo(formName, this.memberId, this.forms[formName].value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response) => {
            this.info[formName] = Object.assign(
              {},
              this.info[formName],
              this.forms[formName].value
            );
            if (formName === "banking") {
              this.buildBankingForm();
            }
            if (formName === "business") {
              this.buildBusinessForm();
            }
            this.isEditing[formName] = false;
            this.isLoading = false;
          },
          ({ error: { error_description } }) => {
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
            this.isLoading = false;
          }
        );
    }
  }

  /**
   * Sets default dates range for Payments list filtering
   */
  private setPaymentsDatesRange(): void {
    const startDay = new Date();
    startDay.setMonth(startDay.getMonth() - 1);
    this.paymentsDatesRange = [startDay, new Date()];
  }

  /**
   * Set validation for some field after toggle business type
   */
  public changeBusinessType(event): void {
    this.forms.business.controls.taxId.clearValidators();
    this.forms.business.controls.taxId.updateValueAndValidity();
    this.forms.business.controls.taxId.setValue(null);

    const validatorName =
      event === "Sole Proprietorship"
        ? "taxIdForSoleProprietorship"
        : "taxIdForAnotherProprietorship";

    this.forms.business.controls.taxId.setValidators([
      Validators.required,
      Validators.pattern(Patterns[validatorName].pattern),
    ]);
    this.taxIdMask = Patterns[validatorName].mask;
  }

  public editBusinessInfo(): void {
    this.isEditing.business = true;
    this.forms.business.controls.taxId.setValue(null);
  }
}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectItem } from "primeng/primeng";
import { ArpOrder } from "../../../../../classes/arp-order";

@Component({
  selector: "app-configure-order-step",
  templateUrl: "./configure-order-step.component.html",
  styleUrls: ["./configure-order-step.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigureOrderStepComponent implements OnInit {
  @Input() arpOrder: ArpOrder;
  @Input() form: FormGroup;
  @Output() onClickNextBtn = new EventEmitter<string>();

  public dayOfMonthOptions: SelectItem[] = [];
  public frequencyOptions: SelectItem[] = [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Every Two Months" },
    { value: 3, label: "Every Three Months" },
  ];
  public usingPointsOptions: SelectItem[] = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  public dialogContentMaxWidth = window.innerWidth - 300;

  /**
   * Vertical window resize handler for opened dialog
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  ngOnInit(): void {
    this.createDayOfMonthOptions();
    this.addControls();
  }

  private addControls(): void {
    this.form.addControl(
      "dayOfMonthToShip",
      new FormControl(null, Validators.required)
    );
    this.form.addControl(
      "interval",
      new FormControl(null, Validators.required)
    );
    this.form.addControl(
      "autoApplyVitaPoints",
      new FormControl(null, Validators.required)
    );
  }

  public backToPreviousStep(): void {
    this.onClickNextBtn.emit("selectProducts");
  }

  private createDayOfMonthOptions(): void {
    for (let i = 1; i < 29; i++) {
      this.dayOfMonthOptions.push({ value: i, label: i.toString() });
    }
  }

  public finishThisStep(): void {
    this.onClickNextBtn.emit("selectShippingAddress");
  }
}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ArpOrder } from "../../../../../classes/arp-order";
import { UserInfo } from "../../../../../classes/user-info";

@Component({
  selector: "app-select-products-step",
  templateUrl: "./select-products-step.component.html",
  styleUrls: ["./select-products-step.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelectProductsStepComponent implements OnInit {
  @Input() arpOrder: ArpOrder;
  @Input() form: FormGroup;
  @Input() user: UserInfo;
  @Output() onClickNextBtn = new EventEmitter<string>();

  public dialogContentMaxWidth = window.innerWidth - 300;

  /**
   * Vertical window resize handler for opened dialog
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.addControls();
  }

  // add control "product" to add/edit arp order form
  private addControls(): void {
    this.form.addControl(
      "products",
      this.formBuilder.array(
        this.arpOrder.products.map((product) =>
          this.buildProductFormGroup(product)
        )
      )
    );
  }

  // add selected products to form fields
  public addSelectedProduct(product): void {
    this.form.controls.products["push"](this.buildProductFormGroup(product));
    // need to call change detection in child datatable
    this.form.controls.products["controls"] = this.form.controls.products[
      "controls"
    ].slice();
  }

  private buildProductFormGroup(product): FormGroup {
    return this.formBuilder.group({
      productName: product.productName,
      itemNumber: product.itemNumber,
      productId: product.productId,
      price: product.price,
      cartImage: product.cartImage,
      quantity: [product.quantity || 1, Validators.min(1)],
    });
  }

  // remove selected product
  public removeSelectedProduct(product): void {
    const removedProductIndex: number = this.form.controls.products.value.findIndex(
      (item) => item.productId === product.productId
    );
    this.form.controls.products["removeAt"](removedProductIndex);
    // need to call change detection in child datatable
    this.form.controls.products["controls"] = this.form.controls.products[
      "controls"
    ].slice();
  }

  // move to next wizard step
  public finishThisStep(): void {
    this.onClickNextBtn.emit("configureOrder");
  }
}

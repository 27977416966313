import { Component, Input } from "@angular/core";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["spinner.component.css"],
})
export class ProgressSpinnerComponent {
  @Input() disableFullScreen = true;
  @Input() spinnerText = "Processing your request...";
}

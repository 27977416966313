import { AuthService } from "./../../services/auth.service";
import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { ConfigService } from "../../services/config.service";
import { IdleService } from "../../services/idle.service";
import { LogOutService } from "../../services/log-out.service";
import { ProfileService } from "../../services/profile.service";
import { UserInfo } from "../../classes/user-info";

interface ITabPanel {
  header: string;
  route: string;
}

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit, OnDestroy {
  currentRouteUrl: string;
  currentUser: UserInfo = new UserInfo();
  iframeOrigin: string;
  iframeSrc: SafeResourceUrl;
  isLoading: boolean;
  isLogoutClick: boolean;
  isAffiliate: boolean;
  tabPanels: ITabPanel[] = [
    {
      header: "Account Managment",
      route: "main/account-management",
    },
    {
      header: "Affiliate Office",
      route: "main/affiliate-office",
    },
  ];
  spinnerText = "Processing your request...";
  sub: Subscription;

  constructor(
    private configService: ConfigService,
    private idleService: IdleService,
    private logOutService: LogOutService,
    private profileService: ProfileService,
    private router: Router,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    this.iframeOrigin = this.configService.getConfiguration().ssoDomain;
  }

  ngOnInit() {
    this.logOutSubjectSubscribe();
    this.idleService.reset();
    this.getProfileInfo();
    this.currentRouteUrl = this.router.url.substring(1);
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeOrigin + `/Auth/LogOut`
    );
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * Defined affiliate user
   */
  getProfileInfo(): void {
    this.currentUser = this.authService.getCurrentUser();
    if (this.currentUser) {
      this.authService.updateUserInfo().subscribe((response) => {
        this.isAffiliate = response.customerRank === 38;
      });
    } else {
      // this.router.navigate(["/login"]);
      this.authService.logOut(); 
    }
  }

  /**
   * On log out button click
   */
  logOutClick() {
    this.logOutService.setLogOutSubject(true);
    this.isLoading = true;
  }

  /**
   * Logout and redirect to login-page
   */
  logOutSubjectSubscribe() {
    this.sub = this.logOutService.logOutSubject.subscribe((response) => {
      this.isLogoutClick = response;
      if (this.isLogoutClick) {
        setTimeout(() => {
          this.isLoading = false;
          this.authService.logOut();
        }, 3000);
      }
    });
  }

  // redirect to clicked page
  redirectToUrl(event) {
    this.router.navigate([this.tabPanels[event.index].route]);
  }

  /**
   Allows to scroll pages to top after their refreshing
   */
  onRouterDeactivate(): void {
    this.renderer.setProperty(document.body, "scrollTop", 0);
  }
}

import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";

@Injectable()
export class PasswordService {
  constructor(
    private httpService: HttpService,
    private authService: AuthService
  ) {}

  changePassword(
    memberId: number,
    passwordForm: { newPassword: string; repeatPassword: string }
  ): Observable<any> {
    delete passwordForm.repeatPassword;
    return this.httpService.put(
      `profile/change-password/${memberId}`,
      "global",
      passwordForm,
      this.authService.getToken()
    );
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LocalStorage } from "../../../helpers/local-storage.decorator";
import { combineLatest, EMPTY, Observable, of } from "rxjs";
import { WellnessStoreState } from "../store/wellness-store.reducer";
import * as S3 from "aws-sdk/clients/s3";
import { catchError, concatMap, map, switchMap } from "rxjs/operators";
import { zip } from "rxjs/internal/observable/zip";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { ConfigService } from "../../../services/config.service";

export const WELLNESS_STORE = "wellness";
export const WELLNESS_CACHE = "cache";

@Injectable()
export class WellnessStoreApiService {
  @LocalStorage() user;
  private bucket: S3;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.bucket = new S3({
      accessKeyId: "AKIA4RNY3CRG6A6SN24B",
      secretAccessKey: "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",
      region: "us-east-1",
    });
  }

  fetchCustomerInfo(user = this.user): Observable<any> {
    return this.http.get(
      `${WELLNESS_STORE}/api/Customer/${user.memberId || user.ID}/Store`
    );
  }

  fetchSiteSettings({ currentStoreID }): Observable<any> {
    return this.http
      .get(`${WELLNESS_STORE}/api/Settings/${currentStoreID}`)
      .pipe(
        concatMap((settings: any) => {
          return combineLatest([
            of(settings),
            this.getMediaEntry(settings?.MediaID),
          ]).pipe(
            map((result) => {
              const data = result[0];
              data.siteImage = result[1] || false;
              return data;
            }),
            catchError((err) => {
              console.error(
                `error occurred during fetching Site Settings`,
                err
              );
              return EMPTY;
            })
          );
        })
      );
  }

  fetchCategories(): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/Product`);
  }

  fetchSocialTypes(): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/Social`);
  }

  fetchMediaTypes(): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/Media`);
  }

  fetchProducts(categories, storeId): Observable<any> {
    return forkJoin(
      categories.map((category) =>
        this.http.get(`${WELLNESS_STORE}/api/Product/${storeId}/${category.ID}`)
      )
    );
  }

  fetchProductsByStoreId(storeId): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/Product/${storeId}`);
  }

  getMediaEntry(ID): Observable<any> {
    if (ID) {
      return this.http.get(`${WELLNESS_STORE}/api/Media/${ID}`).pipe(
        catchError((err) => {
          console.log(`error occured during fetching Media entry ${ID}`, err);
          return EMPTY;
        })
      );
    } else {
      return of(false);
    }
  }

  submitSettings(data: WellnessStoreState): Observable<any> {
    return zip(this.updateSiteImage(data), ...this.updateSocials(data)).pipe(
      concatMap(([image, ...socials]) => {
        const body = {
          ID: data.settings.ID,
          Title: data.newSettings.siteTitle,
          Color: data.newSettings.color,
          // 'DisplaySpecials': data.newSettings.displaySpecials,
          // 'DisplayHOG': data.newSettings.displayHouseGiving,
          Phone: data.newSettings.phone,
          Email: data.newSettings.email,
          SiteAddress: data.newSettings.siteAddress,
          Domain: data.newSettings.domain,
          TimeZoneID: data.newSettings.timezone,
          Social: socials,
          MediaID: image?.ID || 0,
          Story: data.newSettings.story || "",
        };

        return this.http.put(`${WELLNESS_STORE}/api/Settings`, body).pipe(
          catchError((err) => {
            console.log(
              `error occured during saving of site settings ${JSON.stringify(
                body
              )}`,
              err
            );
            return EMPTY;
          })
        );
      })
    );
  }

  updateSocials(state: WellnessStoreState): Observable<any>[] {
    return Object.entries(state.newSettings).reduce((acc, [key, URL]) => {
      const prevSocial = state.settings.Social.find(
        (socialType) => socialType.Type.Description === key
      );

      if (prevSocial) {
        if (prevSocial.URL === URL) {
          acc.push(of(prevSocial));
          return acc;
        } else {
          const body = {
            ID: prevSocial.ID,
            StoreID: state.settings.ID,
            Type: {
              ID: prevSocial.Type.ID,
            },
            URL,
          };

          acc.push(this.http.put(`${WELLNESS_STORE}/api/Social`, body));
          return acc;
        }
      }

      const social = state.socialTypes.find(
        (socialType) => socialType.Description === key
      );

      if (social) {
        const body = {
          StoreID: state.settings.ID,
          Type: {
            ID: social.ID,
          },
          URL,
        };

        acc.push(this.http.post(`${WELLNESS_STORE}/api/Social`, body));
        return acc;
      }

      return acc;
    }, []);
  }

  submitProducts(state) {
    const StoreID = state.settings.ID;

    return combineLatest(
      ...state.selectedProducts
        .filter((product) => !product.saved)
        .map((product) => {
          const body = {
            ID: 0,
            StoreID,
            Product: {
              ID: product.Product.ID,
            },
          };

          return this.http
            .post(`${WELLNESS_STORE}/api/StoreProduct`, body)
            .pipe(
              catchError((err) => {
                console.error(
                  `error occured during saving product ${JSON.stringify(body)}`,
                  err
                );
                return EMPTY;
              })
            );
        }),
      ...state.removedProducts.map((product) => {
        return this.http.delete(
          `${WELLNESS_STORE}/api/Store/${StoreID}/Product/${product.Product.ID}/Remove`
        );
      })
    );
  }

  fetchArticleTypes(): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/Article`);
  }

  private updateSiteImage({
    newSettings,
    settings,
    mediaTypes,
  }): Observable<any> {
    if (newSettings.siteImage.ID) {
      return of(newSettings.siteImage);
    }
    const contentType = newSettings.siteImage?.type;
    const [result] = mediaTypes.filter((type) => type.MIME === contentType);

    if (result?.ID && contentType) {
      return this.uploadImage(newSettings.siteImage, settings.ID).pipe(
        concatMap((data: any) => {
          const body = {
            MediaType: {
              ID: result.ID,
            },
            URL: data.Location,
          };
          return this.http.post(`${WELLNESS_STORE}/api/Media`, body).pipe(
            catchError((err) => {
              console.error(
                `error occured during saving site image ${JSON.stringify(
                  body
                )}`,
                err
              );
              return EMPTY;
            })
          );
        })
      );
    } else {
      return of(false);
    }
  }

  private uploadImage(image, ID): Observable<any> {
    const params = {
      Bucket: `images.mywellnessstore.com/${ID}`,
      Key: image.name,
      Body: image,
      ACL: "public-read",
      ContentType: image.type,
    };

    return Observable.create((observer) => {
      this.bucket.upload(params, function (err, data) {
        if (err) {
          observer.error("There was an error uploading your file: ", err);
        }

        observer.next(data);
      });
    });
  }

  private convertToFiles(base64Arr): File[] {
    return base64Arr.map((base) => {
      const arr = base.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], `${Date.now()}.${mime.split("/")[1]}`, {
        type: mime,
      });
    });
  }

  private convertToImages(message: string, ID): Observable<any>[] {
    if (!message) {
      return [of(false)];
    }
    // @ts-ignore
    const base64Arr = [...message.matchAll(/src="(data:[^>]+)"/g)].map(
      (match) => (match ? match[1] : null)
    );

    const files = this.convertToFiles(base64Arr);
    return files.map((file) => this.uploadImage(file, ID));
  }

  private replaceBase64toURLs(str: string, imgArr: any[]): string {
    let newStr = str;

    imgArr.forEach((img) => {
      newStr = newStr.replace(/data:[^"]+/, img.Location);
    });

    return newStr;
  }

  publishPost(post, state): Observable<any> {
    const messageImages = this.convertToImages(post.message, state.settings.ID);
    const imagesArr = post.media.map((image) =>
      this.uploadImage(image, state.settings.ID)
    );

    const streams = [
      imagesArr?.length ? combineLatest(imagesArr) : of([]),
      messageImages?.length ? combineLatest(messageImages) : of([]),
    ];
    // const allowedImages = [];
    // const forbiddenImages = [];
    //
    // post.media.forEach(image => {
    //   if (state.mediaTypes.filter(media => media.MIME === image.type).length) {
    //     allowedImages.push(image);
    //   } else {
    //     forbiddenImages.push(image);
    //   }
    // });
    //
    // if (forbiddenImages.length) {
    //   forbiddenImages.forEach(image => console.error(`This image has forbidden type`, image));
    // }
    return combineLatest(streams).pipe(
      switchMap(([mediaImgs, contentImgs]) => {
        const [{ ID }] = state.articleTypes.filter(
          (type) => type.Description === post.postType
        );

        const message = contentImgs?.length
          ? this.replaceBase64toURLs(post.message, contentImgs)
          : post.message;

        const body = {
          Store: {
            ID: state.settings.ID,
          },
          Type: {
            ID,
          },
          Title: post.title || "",
          Content: message,
          ArticleDescription: post.blogDescription || "",
          DisplayStart: post.date.toISOString(),
          Media: mediaImgs.map((image: any) => {
            const [mediaType] = state.mediaTypes.filter((media) => {
              const imgArr = image.Location.split(".");
              let ext = imgArr[imgArr.length - 1];

              if (ext === "jpg") {
                ext = "jpeg";
              }

              return media.MIME.includes(ext);
            });

            return {
              URL: image.Location,
              MediaType: {
                ID: mediaType?.ID || 1,
              },
            };
          }),
        };

        return this.http.post(`${WELLNESS_STORE}/api/Article`, body);
      })
    );
  }

  fetchArticles(state, type, page = 1, include = 3): Observable<any> {
    return this.http.get(
      `${WELLNESS_STORE}/api/Store/${state.settings.ID}/Article/${type}/${include}/4/${page}`
    );
  }

  updateArticle({ post }, state): Observable<any> {
    const body = {
      ...post.data,
    };

    const messageImages = body.Content
      ? this.convertToImages(body.Content, state.settings.ID)
      : [];
    const imagesArr = post.uploadedMedia.map((image) =>
      this.uploadImage(image, state.settings.ID)
    );

    const streams = [
      imagesArr?.length ? combineLatest(imagesArr) : of([]),
      messageImages?.length ? combineLatest(messageImages) : of([]),
    ];

    return combineLatest(streams).pipe(
      concatMap(([mediaImgs, contentImgs]) => {
        body.Media = body.Media.concat(
          mediaImgs.map((image) => {
            const [mediaType] = state.mediaTypes.filter((media) => {
              const imgArr = image.Location.split(".");

              return media.MIME.includes(imgArr[imgArr.length - 1]);
            });
            return {
              URL: image.Location,
              MediaType: {
                ID: mediaType.ID,
              },
            };
          })
        );

        body.Content = contentImgs?.length
          ? this.replaceBase64toURLs(body.Content, contentImgs)
          : body.Content;

        return this.http.put(`${WELLNESS_STORE}/api/Article`, body);
      })
    );
  }

  deleteArticle({ post }): Observable<any> {
    return this.http.delete(`${WELLNESS_STORE}/api/Article/${post.post.ID}`);
  }

  deleteImages({ images }): Observable<any> {
    return forkJoin(
      ...images.map((image) =>
        this.http.delete(`${WELLNESS_STORE}/api/Media/${image.ID}/Remove`)
      )
    );
  }

  fetchBlogPosts(page = 1): Observable<any> {
    return this.http.get(
      `https://www.trivita.com/wp-json/wp/posts?per_page=4&page=${page}`
    );
  }

  postTrivitaBlogs(state): Observable<any> {
    const StoreID = state.settings.ID;

    const selected = state.selectedTrivitaBlogs
      .filter((post) => !post.saved)
      .map((post) =>
        this.http.post(`${WELLNESS_STORE}/api/Blog`, {
          StoreID,
          WPBlogID: post.ID,
        })
      );
    const deleted = state.deletedTrivitaBlogs.map((post) =>
      this.http.delete(
        `${WELLNESS_STORE}/api/Blog/${post.ID}/Store/${StoreID}/Remove`
      )
    );

    return combineLatest(selected.concat(deleted));
  }

  fetchSelectedProducts(state): Observable<any> {
    return this.http.get(
      `${WELLNESS_STORE}/api/Store/${state.settings.ID}/Product`
    );
  }

  fetchTrivitaSelectedBlogs({ settings }): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/Store/${settings.ID}/Blog`);
  }

  clearCache({ clearType: type }, storeID): Observable<any> {
    switch (type) {
      case "Settings":
        return this.http.post(`${WELLNESS_CACHE}/api/stores/${storeID}`, {});
      case "Products":
        return this.http.post(
          `${WELLNESS_CACHE}/api/stores/${storeID}/products`,
          {}
        );
      case "Posts":
        return this.http.post(
          `${WELLNESS_CACHE}/api/stores/${storeID}/posts`,
          {}
        );
      case "Feed":
        return this.http.post(
          `${WELLNESS_CACHE}/api/stores/${storeID}/feed`,
          {}
        );
    }
  }

  fetchUsers(): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/Store`);
  }

  fetchArticleToApprove(ArticleType = "Feed", PageNumber = 1): Observable<any> {
    return this.http.get(
      `${WELLNESS_STORE}/api/Admin/Article/${ArticleType}/4/${PageNumber}`
    );
  }

  setResolution(body): Observable<any> {
    return this.http.put(`${WELLNESS_STORE}/api/Admin/Article`, body);
  }

  fetchSocialApiAccessKeys(ID): Observable<any> {
    return this.http.get(`${WELLNESS_STORE}/api/AccessKey/${ID}`);
  }

  saveToken(body): Observable<any> {
    return this.http.post(`${WELLNESS_STORE}/api/AccessKey`, body);
  }

  fetchTwitterToken(): Observable<any> {
    const params = new HttpParams().set(
      "callback_url",
      `${this.config.config.twitterCallbackDomain}/wellness-store/user/select-store`
    );
    return this.http.get("https://www.trivita.com/tw-token.php", { params });
  }

  fetchAgreement(state): Observable<any> {
    return this.http.get(
      `${WELLNESS_STORE}/api/Store/${state.currentStoreID}/Agreement/0`
    );
  }

  signedAgreement(agreements, ID): Observable<any[]> {
    return forkJoin(
      ...agreements.map((agreement) =>
        this.http.get(
          `${WELLNESS_STORE}/api/Agreement/${agreement.ID}/Store/${ID}`
        )
      )
    );
  }

  acceptAgreement(state): Observable<any[]> {
    return forkJoin(
      ...state.agreement.map((agreement) =>
        this.http.post(`${WELLNESS_STORE}/api/Agreement/`, {
          Agreement: {
            ID: agreement.Agreement.ID,
          },
          StoreID: state.currentStoreID,
        })
      )
    );
  }

  removeSocialKey(ID): Observable<any> {
    return this.http.delete(`${WELLNESS_STORE}/api/AccessKey/${ID}/Remove`);
  }

  checkAvailability(query): Observable<any> {
    return this.http.get(
      `${WELLNESS_STORE}/api/Store/Search?domainOrAddress=${query}`
    );
  }

  fetchFacebookPages(data, state): Observable<any> {
    const [SocialType] = state.socialTypes.filter(
      (item) => item.Description === "Facebook"
    );
    const body = {
      StoreID: state.settings.ID,
      SocialType,
      AccessToken: data.authToken,
    };

    return this.http.post(`${WELLNESS_STORE}/api/FacebookPageAccessKey`, body);
  }
}

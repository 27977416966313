import { AuthService } from './auth.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';

import {CardModel} from '../classes/card-model';

@Injectable()
export class PaymentService {
  userToken: string;

  constructor(private httpService: HttpService, private authService: AuthService) {
    this.userToken = this.authService.getToken();
  }

  // get payment options from api
  getCardTypes(): Observable<CardModel> {
    return this.httpService.get(`cardhelper/`, 'cart');
  }

  getPaymentList(memberId) {
    return this.httpService.get(`card/${memberId}`, 'global', this.userToken);
  }
}

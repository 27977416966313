import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "../../../services/login.service";
import { showError } from "../../../helpers/form-helper";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-reset-password-form",
  templateUrl: "./reset-password-form.component.html",
  styleUrls: ["../forgot-password.component.scss"],
})
export class ResetPasswordFormComponent {
  @Input() nextStepName: string;
  @Output() onResetPasswordRequest = new EventEmitter<string>();

  public isLoading = false;
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public showError: Function = showError;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private messageService: MessageService
  ) {
    this.buildForm();
  }

  private buildForm(): void {
    // TODO: add getEnteredEMail from logic service as done in Homedoc
    // TODO: move pattern to some file as was done in homedoc
    // get entered email from login-form
    const email = this.loginService.getEnteredEmail();
    this.resetPasswordForm = this.fb.group({
      email: [email, [Validators.required]],
    });
  }

  private goToNextStep(): void {
    this.onResetPasswordRequest.emit(this.nextStepName);
  }

  public onSubmit(): void {
    this.submitted = true;
    const redirectUrl = encodeURIComponent(location.href);
    if (this.resetPasswordForm.valid && this.resetPasswordForm.dirty) {
      this.isLoading = true;
      this.loginService
        .sendResetPasswordRequest(
          this.resetPasswordForm.controls["email"].value,
          redirectUrl
        )
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.goToNextStep();
          },
          ({ error: { error_description } }) => {
            this.isLoading = false;
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        );
    }
  }
}

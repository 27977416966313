import { AuthService } from './../../../services/auth.service';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AffiliateService } from "../../../services/affiliate.service";
import { UserInfo } from "../../../classes/user-info";
import { Customer } from "../../../classes/customer";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ContactsComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserInfo = new UserInfo();
  @Input() panelCollapsed: boolean;

  private customersDatatable = {
    pageIndex: 0,
    pageSize: 25,
    sortColumn: null,
    sortOrder: null,
    totalCustomers: null,
  };
  private destroy$ = new Subject();

  public customers: Customer[];
  public selectedCustomers: Customer[];
  public isLoading = false;

  constructor(
    private affiliateService: AffiliateService,
    private AuthService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getContacts();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get contacts from service
  private getContacts(): void {
    this.affiliateService
      .getContacts(
        this.currentUser.memberId,
        this.customersDatatable.pageIndex,
        this.customersDatatable.pageSize,
        this.customersDatatable.sortColumn,
        this.customersDatatable.sortOrder
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (contacts: any) => {
          this.customersDatatable.totalCustomers = contacts.totalCustomers;
          this.customers = contacts.customers;
          this.isLoading = false;
        },
        ({ error: { error_description }, status }) => {
          this.isLoading = false;
          if ([401, 403].includes(status)) {
            this.AuthService.logOut();
          } else {
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        }
      );
  }
}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectItem } from "primeng";
import { MessageService } from "primeng/api";

import { PaymentService } from "../../../../../services/payment.service";
import { Address, ArpOrder, Payment } from "../../../../../classes/arp-order";
import { Country } from "../../../../../classes/country";
import { WizardHelperService } from "../../../../../services/wizard-helper.service";

@Component({
  selector: "app-select-payment-method-step",
  templateUrl: "./select-payment-method-step.component.html",
  styleUrls: ["./select-payment-method-step.component.scss"],
})
export class SelectPaymentMethodStepComponent implements OnInit {
  @Input() arpOrder: ArpOrder;
  @Input() billingAddress: Address;
  @Input() country: Country;
  @Input() form: FormGroup;
  @Input() memberId: string;

  @Output() onDialogDecentered = new EventEmitter<null>();
  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onChangeStepTitle = new EventEmitter<string>();

  private creditCardsTypes: SelectItem[] = [];

  public dialogContentMaxWidth: number = window.innerWidth - 300;
  public isLoading = false;
  public paymentList = [];
  public spinnerText: string;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(
    private paymentService: PaymentService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    if (!this.form.controls.cardId) {
      this.addControls();
    }
    this.getPaymentOptions();
    this.getCardList();
  }

  // add 'payment' field to general form
  private addControls(): void {
    this.form.addControl("cardId", new FormControl("", Validators.required));
  }

  // close add credit card form
  public addPayment(): void {
    delete this.form.controls.cardId;
    this.onClickNextBtn.emit("addEditPaymentMethod");
  }

  // move to previous wixard step
  public backToPreviousStep(): void {
    this.onClickNextBtn.emit("selectShippingAddress");
  }

  // handler toggle btn event
  public changeCheckedPayment(checked, payment): void {
    delete this.form.controls.payment;
    if (checked) {
      this.form.controls.cardId.setValue(payment.cardId);
      this.paymentList.map((item) => {
        item.checked = false;
        if (item.card.cardId === payment.cardId) {
          item.checked = true;
        }
      });
    } else {
      this.form.controls.cardId.setValue("");
      this.paymentList.map((item) => {
        item.checked = false;
      });
    }
  }

  // get all cards for this memberId and set card list to object
  private getCardList(): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.paymentService.getPaymentList(this.memberId).subscribe(
      (response) => {
        this.isLoading = false;
        if (!response.cards.length) {
          this.addPayment();
        } else {
          this.wizardHelperService.setCardList(response.cards);
          this.paymentList = response.cards;
          this.transformPaymentList(this.paymentList);
          this.wizardHelperService.setCardList(response.cards);

          setTimeout(() => this.onDialogDecentered.emit());
        }
      },
      ({ error: { error_description } }) => {
        this.isLoading = false;
        this.messageService.add({
          severity: "error",
          summary: error_description,
        });
      }
    );
  }

  // get credit card options for add/edit card form
  private getPaymentOptions(): void {
    this.paymentService.getCardTypes().subscribe(
      (response) => {
        const creditCardsTypes = response.countries[2].cardTypes;
        for (let i = 0; i < creditCardsTypes.length; i++) {
          this.creditCardsTypes.push({
            value: creditCardsTypes[i].value.toString(),
            label: creditCardsTypes[i].displayName,
          });
        }
      },
      ({ error: { error_description } }) => {
        this.messageService.add({
          severity: "error",
          summary: error_description,
        });
      }
    );
  }

  /**
   * Updarte component addresses list and set shipping address id
   * @param addresses - addresses list
   */
  private transformPaymentList(paymentList: Payment[]): void {
    const paymentItems = paymentList.map((card) => {
      if (
        this.form.controls.cardId &&
        card.cardId === this.form.controls.cardId.value
      ) {
        return { card, checked: true };
      }
      return { card, checked: false };
    });
    this.paymentList = paymentItems.slice();
  }

  // move to next wizard step
  public finishThisStep(): void {
    this.onClickNextBtn.emit("selectBillingAddress");
  }
}

import { AuthService } from './../../../services/auth.service';
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";

import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { PasswordService } from "../../../services/password.service";
import { showError } from "../../../helpers/form-helper";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit {
  @Input() panelCollapsed: boolean;
  @Input() memberId: number;

  public isLoading = false;
  public changePasswordForm: FormGroup;
  public showError: Function = showError;
  public submitted = false;
  public spinnerText: string;

  constructor(
    private fb: FormBuilder,
    private gaService: GoogleAnalyticsService,
    private passwordService: PasswordService,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  /**
   * Build model driven changePasswordForm form
   */
  private buildForm(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      repeatPassword: ["", Validators.required],
    });
  }

  /**
   * Change current password
   */
  public onSubmit(): void {
    this.submitted = true;
    if (
      this.changePasswordForm.valid &&
      this.changePasswordForm.dirty &&
      this.changePasswordForm.controls["newPassword"].value ===
        this.changePasswordForm.controls["repeatPassword"].value
    ) {
      this.isLoading = true;
      this.spinnerText = "Processing your request...";
      this.passwordService
        .changePassword(this.memberId, this.changePasswordForm.value)
        .subscribe(
          (response) => {
            this.isLoading = false;
            this.spinnerText = null;
            this.messageService.add({
              severity: "success",
              summary: "Your Password was changed",
            });
            this.resetForm();
            this.gaService.sendGoogleAnalyticsEvent("changePassword");
          },
          ({ error: { error_description } }) => {
            this.isLoading = false;
            this.spinnerText = null;
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        );
    }
  }

  public onForgotPasswordClick(): void {
    this.gaService.sendGoogleAnalyticsEvent("forgotPassword");
    this.authService.logOut();
    this.router.navigate(["/forgot-password"]);
  }

  /**
   * Password panel toggle handler
   * @param event - dom event object
   */
  public onPanelToggle(event: {
    originalEvent: Event;
    collapsed: boolean;
  }): void {
    if (!event.collapsed) {
      this.gaService.sendGoogleAnalyticsEvent("passwordOpen");
    }
  }

  // reset form
  public resetForm(): void {
    this.submitted = false;
    this.changePasswordForm.reset();
  }

  onResetClick(){
    this.authService.resetPassword(); 
  }
}

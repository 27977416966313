import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TextMaskModule } from "angular2-text-mask";
import { ClipboardModule } from "ngx-clipboard";
import { ToastModule } from "primeng/toast";

import { SharedModule } from "../../modules/shared/shared.module";

// components
import { AffiliateBasicInfoComponent } from "./affiliate-basic-info/affiliate-basic-info.component";
import { AffiliateOfficeComponent } from "./affiliate-office.component";
import { CompensationInformationComponent } from "./compensation-information/compensation-information.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { EmailVideoMessageDialogComponent } from "./media-library/email-video-message-dialog/email-video-message-dialog.component";
import { EmbedVideoCodeDialogComponent } from "./media-library/embed-video-code-dialog/embed-video-code-dialog.component";
import { MediaLibraryComponent } from "./media-library/media-library.component";
import { MessageCenterComponent } from "./message-center/message-center.component";
import { PrintedMaterialComponent } from "./printed-material/printed-material.component";
import { TutorialsComponent } from "./tutorials/tutorials.component";
import { InputTextModule } from "primeng";

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    TextMaskModule,
    SharedModule,
    InputTextModule,
    ToastModule
  ],
  declarations: [
    AffiliateBasicInfoComponent,
    AffiliateOfficeComponent,
    EmailVideoMessageDialogComponent,
    EmbedVideoCodeDialogComponent,
    CompensationInformationComponent,
    ContactsComponent,
    MediaLibraryComponent,
    MessageCenterComponent,
    PrintedMaterialComponent,
    TutorialsComponent,
  ],
})
export class AffiliateOfficeModule {}

import { AuthService } from '../../services/auth.service';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { UserInfo } from "../../classes/user-info";
import { Country } from "../../classes/country";
import { CountryService } from "../../services/country.service";
import {HttpService} from '../../services/http.service';
import {ConfigService} from '../../services/config.service';
import {filter} from 'rxjs/operators';

interface IPanelsCollapsed {
  contactInformation: boolean;
  paymentMethod: boolean;
  orderHistory: boolean;
  autoReplenishmentProgram: boolean;
  referAFriend: boolean;
  password: boolean;
}

@Component({
  selector: "app-account-managment",
  templateUrl: "./account-managment.component.html",
  styleUrls: ["./account-managment.component.scss"],
})
export class AccountManagmentComponent implements OnInit, OnDestroy {
  public country: Country;
  public currentUser: UserInfo = new UserInfo();
  public panelsCollapsed: IPanelsCollapsed = {
    contactInformation: true,
    paymentMethod: true,
    orderHistory: true,
    autoReplenishmentProgram: true,
    referAFriend: true,
    password: true,
  };
  public referAFriendIsVisible: boolean;

  private getCountriesWithStatesSubscription: Subscription;
  public ocenture;
  public ocentureExpired: boolean;

  constructor(
    private countryService: CountryService,
    private router: Router,
    private authService: AuthService,
    private http: HttpService,
    private configService: ConfigService
  ) {}

  private reloadCurrentRoute(): void {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  ngOnInit() {
    this.reloadCurrentRoute();
    this.checkUserIsLogged();
    this.initOcenture();
  }

  private initOcenture(): void {
    this.authService.ocentureStream
      .pipe(filter(Boolean))
      .subscribe(ocenture => {
        this.ocenture = ocenture;
        this.ocentureExpired = +new Date(this.ocenture[0].ExpiryDate) < +new Date();
    });
  }

  ngOnDestroy() {
    if (this.getCountriesWithStatesSubscription) {
      this.getCountriesWithStatesSubscription.unsubscribe();
    }
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  private checkUserIsLogged(): void {
    this.currentUser = this.authService.getCurrentUser();

    this.referAFriendIsVisible =
      this.currentUser &&
      (this.currentUser.isPremierMember === true ||
        this.currentUser.isPremierMember === "true" ||
        this.currentUser.isItbo === true ||
        this.currentUser.isItbo === "true" ||
        this.currentUser.isAffiliate === true ||
        this.currentUser.isAffiliate === "true");

    if (!this.currentUser && location.pathname !== "/forgot-password") {
      // this.router.navigate(["/login"]);
      this.authService.logOut(); 
    } else {
      this.defineCurrentCountry();
    }
  }

  /**
   * Get country for a country drop down along with their associated states
   */
  private defineCurrentCountry(): void {
    const countryId = this.currentUser.billingAddress.countryId;
    this.getCountriesWithStatesSubscription = this.countryService
      .getCountriesWithStates()
      .subscribe((response) => {
        this.country = response.find((item) => item.value === countryId);
      });
  }

  /**
   * Expands one of panel which exists in child components
   * @param panelName - name of property of this.panelsCollapsed object
   */
  public expandPanel(panelName: string): void {
    this.panelsCollapsed[panelName] = false;
  }

  /**
   *
   * @param event - primeng generated event
   * @param panelName - name of property of this.panelsCollapsed object
   */
  public updatePanelCollapsed(
    event: { originalEvent: Event; collapsed: boolean },
    panelName: string
  ): void {
    this.panelsCollapsed[panelName] = event.collapsed;
  }

  telehealth(): void {
    window.open(
      `${this.ocenture[0].URL}`,
      '_blank'
    );
  }

  prayer(): void {
    window.open(
      `${this.configService.getConfiguration().prayerHelplineLink}`,
      '_blank'
    );
  }

  reactivate(): void {
    const url = this.configService.getConfiguration().cart_trivita;
    const route = `/?dsCart=false&prodList=${this.ocenture.ProdID}|1,${this.ocenture.ReinstatementProdID}|1&siteId=4&countryId=${this.ocenture.StoreID}&sourceId=0`;

    window.open(
      url + route,
      '_blank'
    );
  }

  cancelPMPsubscription(): void {
    this.authService.cancelPMPsubscription().subscribe();
  }
}

import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { HttpService } from "./http.service";
import { ConfigService } from "./config.service";

@Injectable()
export class AddressService {
  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private authService: AuthService
  ) {}

  get userToken(): string {
    if (this.authService.getCurrentUser()) {
      return this.authService.getToken();
    }
  }

  // Get all addresses
  getAllAddresses(memberId) {
    return this.httpService.get(
      `address/${memberId}`,
      "global",
      this.userToken
    );
  }

  // Get address by id
  getAddressById(memberId, addressIds: number[], userToken: string) {
    const forkJoinArray: Observable<any>[] = [];
    for (const addressId of addressIds) {
      const observable = this.httpService.get(
        `address/${memberId}?addressId=${addressId}`,
        "global",
        userToken
      );
      forkJoinArray.push(observable);
    }
    return forkJoin(forkJoinArray);
  }

  // Remove address
  removeAddress(memberId, addressId) {
    return this.httpService.put(
      `address/${memberId}?addressId=${addressId}`,
      "global",
      null,
      this.userToken
    );
  }

  // Update exist or create new addresses
  updateAddressList(address: any) {
    const memberId = this.authService.getCurrentUser().memberId;
    address.addressId = address.addressId ? address.addressId : 0;
    return this.httpService.post(
      `address/${memberId}`,
      "global",
      address,
      this.userToken
    );
  }
}

import {Injectable} from '@angular/core';
import {Configuration} from '../classes/configuration';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ConfigService {

  private _config: Configuration;

  constructor(private http: HttpClient) {
  }

  get config(): Configuration {
    return this._config;
  }

  load(url: string) {
    return new Promise<void>((resolve) => {
      this.http.get(url)
        .subscribe((config: Configuration) => {
          this._config = config;
          resolve();
        }, err => {
          console.log(err);
        });
    });
  }

  getConfiguration(): Configuration {
    return this._config;
  }
}

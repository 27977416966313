import { AuthService } from "./../../services/auth.service";
import { Component, OnInit } from "@angular/core";

import { UserInfo } from "../../classes/user-info";

interface IPanelsCollapsed {
  affiliateBasicInfo: boolean;
  compensationInformation: boolean;
  contacts: boolean;
  mediaLibrary: boolean;
  messageCenter: boolean;
  printedMaterial: boolean;
  tutorials: boolean;
}

@Component({
  selector: "app-affiliate-office",
  templateUrl: "./affiliate-office.component.html",
  styleUrls: ["./affiliate-office.component.scss"],
})
export class AffiliateOfficeComponent implements OnInit {
  public currentUser: UserInfo = new UserInfo();
  public panelsCollapsed: IPanelsCollapsed = {
    affiliateBasicInfo: true,
    compensationInformation: true,
    contacts: true,
    mediaLibrary: true,
    messageCenter: false,
    printedMaterial: true,
    tutorials: true,
  };

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
  }
}

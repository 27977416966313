import { AuthService } from './services/auth.service';
import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { StoreParamsService } from "./services/store-params.service";
import { UserInfo } from "./classes/user-info";
import { LocalStorage } from "./helpers/local-storage.decorator";
import { filter, map } from "rxjs/operators";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @LocalStorage() user: UserInfo;

  public showHeader;
  isLoggedIn = false;

  constructor(
    private gaService: GoogleAnalyticsService,
    private storeParamsService: StoreParamsService,
    private router: Router,
    private renderer: Renderer2,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.setBodyThemeClass(
      this.storeParamsService.getQueryParameterByName("siteId")
    );
    this.gaService.initGoogleAnalytics();

    if (this.user) {
      this.authService.setCurrentUser(this.user);
    } else {
      this.checkUserIsLogged();
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects)
      )
      .subscribe((path) => {
        this.showHeader = !["/login", "/wellness-store/agreement"].includes(
          path
        );
      });

      this.authService.loginChanged$.subscribe((changed) => {
        this.isLoggedIn = this.authService.isLoggedIn();
      });
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  checkUserIsLogged(): void {
    return this.authService.isLoggedIn();
  }

  /**
   Allows to scroll pages to top after their refreshing
   */
  onRouterDeactivate(): void {
    this.renderer.setProperty(document.body, "scrollTop", 0);
  }

  /**
   * Get passed in query params site id (site user came from) and
   * set specify class to body tag for styling child elements
   * according to design that site
   * See ass
   * ets/styles/themes/ directory
   */
  setBodyThemeClass(siteId: string = "1") {
    document.querySelector("body").classList.remove(`theme-site-id-1`); // remove default class
    document.querySelector("body").classList.add(`theme-site-id-${siteId}`); // apply actual class
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SelectItem } from "primeng/primeng";
import { Payment } from "../../../../../../classes/arp-order";

@Component({
  selector: "app-credit-card-form",
  templateUrl: "./credit-card-form.component.html",
  styleUrls: ["./credit-card-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreditCardFormComponent implements OnInit {
  @Input() creditCardsTypes: SelectItem[] = [];
  @Input() form: FormGroup;
  @Input() isPaymentManagement: boolean;

  @Output() onAddEditCard = new EventEmitter<Payment>();
  @Output() onChangeStep = new EventEmitter<null>();
  @Output() onEndAddEditAddress = new EventEmitter<null>();

  public cardNumberExample: string;
  public months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  public years: SelectItem[];
  
  private submitted = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.setupPaymentControls();
    this.createCardYearsList();
  }

  // setup 'payment' field to general form
  private setupPaymentControls(): void {
    delete this.form.controls.payment; // remove passed payment data from parent components

    this.form.addControl(
      "payment",
      this.formBuilder.group({
        nameOnCard: ["", Validators.required],
        cardNumber: ["", Validators.required],
        cardType: ["", Validators.required],
        cardExpMonth: ["", Validators.required],
        cardExpYear: ["", Validators.required],
        cardCvvCode: ["", Validators.required],
        cardId: [0],
      })
    );
  }

  /**
   * Return boolean is invalid passed form control or not
   * @param controlName {string}
   */
  public controlIsNotValid(controlName: string): boolean {
    return this.form.controls.payment["controls"][controlName].invalid;
  }

  // defined validation of card number
  public onCardTypeChange({ originalEvent, value }): void {
    this.cardNumberExample =
      value === "AmericanExpress"
        ? "XXXX XXXXXX XXXXX"
        : "XXXX XXXX XXXX XXXX";
    const numberPattern =
      value === "AmericanExpress"
        ? Patterns.cardNumber.amex
        : Patterns.cardNumber.other;
    const cvvPattern =
      value === "AmericanExpress"
        ? Patterns.cardCvv.amex
        : Patterns.cardCvv.other;
    this.form.controls.payment["controls"]["cardNumber"].reset();
    this.form.controls.payment["controls"]["cardCvvCode"].reset();
    this.form.controls.payment["controls"]["cardNumber"].setValidators(
      Validators.pattern(numberPattern)
    );
    this.form.controls.payment["controls"]["cardCvvCode"].setValidators(
      Validators.pattern(cvvPattern)
    );
  }

  /**
   * Create dropdown years list based on current year (includes current and 49 next)
   */
  private createCardYearsList(): SelectItem[] {
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let i = 0; i < 50; i++) {
      this.years.push({
        value: (currentYear + i).toString(),
        label: (currentYear + i).toString(),
      });
    }
    return this.years;
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.form.controls.payment.valid) {
      this.onAddEditCard.emit();
    }
  }

  /**
   * Show validation error when form is invalid and was submitted or is dirty
   * @param controlName {string}
   */
  public showError(controlName: string): boolean {
    return (
      (this.form.controls.payment["controls"][controlName].invalid &&
        this.form.controls.payment["controls"][controlName].dirty) ||
      (this.form.controls.payment["controls"][controlName].invalid &&
        this.submitted)
    );
  }

  // move to next wizard step
  // public finishThisStep(): void {
  //   this.onChangeStep.emit();
  // }

  public backToPreviousStep(): void {
    delete this.form.controls.payment;
    this.onChangeStep.emit();
  }
}

const Patterns = {
  cardNumber: {
    amex: /^\d{4}\s{0,1}\d{6}\s{0,1}\d{5}$/,
    other: /^\d{4}\s{0,1}\d{4}\s{0,1}\d{4}\s{0,1}\d{4}$/,
  },
  cardCvv: {
    amex: /^[0-9]{4}$/,
    other: /^[0-9]{3}$/,
  },
  email: /^(\S+)@(\S+)\.(\S{2})/,
  phone: {
    mask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    pattern: /\(\d{3}\)\s\d{3}-\d{4}/,
  },
  socialSecurityNumber: {
    mask: [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
    pattern: /\d{3}-\d{2}-\d{4}/,
  },
  taxId: {
    mask: [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    pattern: /\d{2}-\d{7}/,
  },
};

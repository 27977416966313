import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";

import { LoginService } from "../../../services/login.service";
import { showError } from "../../../helpers/form-helper";

@Component({
  selector: "app-create-password-form",
  templateUrl: "./create-password-form.component.html",
  styleUrls: ["../forgot-password.component.scss"],
})
export class CreatePasswordFormComponent {
  @Input() memberId: string;
  @Input() nextStepName: string;
  @Input() token: string;
  @Output() onResetPasswordRequest = new EventEmitter<string>();

  public createPasswordForm: FormGroup;
  public isLoading = false;
  public showError: Function = showError;
  public submitted = false;

  private password: string;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private messageService: MessageService
  ) {
    this.buildForm();
  }

  private buildForm(): void {
    this.createPasswordForm = this.fb.group({
      password: [
        this.password,
        [
          Validators.required,
          Validators.maxLength(11),
          Validators.minLength(6),
          Validators.pattern(/^[a-zA-Z0-9]*$/),
        ],
      ],
    });
  }

  private goToNextStep(): void {
    this.onResetPasswordRequest.emit(this.nextStepName);
  }

  public onSubmit(): void {
    this.submitted = true;
    this.isLoading = true;
    if (this.createPasswordForm.valid && this.createPasswordForm.dirty) {
      this.loginService
        .sendNewPassword(
          this.memberId,
          this.token,
          this.createPasswordForm.controls["password"].value
        )
        .subscribe(
          (response) => {
            this.isLoading = false;
            this.goToNextStep();
          },
          ({ error: { error_description } }) => {
            this.isLoading = false;
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        );
    }
  }
}

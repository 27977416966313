import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { ArpOrderService } from "../../../../services/arp-order.service";
import { ArpOrder, Payment } from "../../../../classes/arp-order";
import { Country } from "../../../../classes/country";
import { UserInfo } from "../../../../classes/user-info";

@Component({
  selector: 'app-arp-order-wizard',
  templateUrl: './arp-order-wizard.component.html',
  styleUrls: ['./arp-order-wizard.component.scss']
})

export class ArpOrderWizardComponent implements OnInit, OnDestroy {
  @Input() country: Country;
  @Input() orderList: ArpOrder[];
  @Input() visible: boolean;
  @Input() user: UserInfo;

  @Output() onAddOrder = new EventEmitter<ArpOrder[]>();
  @Output() onToggleWizardDialog = new EventEmitter<boolean>();

  // TODO: update for edit too
  arpOrder: ArpOrder = new ArpOrder(
    [],
    null,
    null,
    null,
    null,
    null,
    new Payment()
  );
  currentStep: WizardStep;
  dialogCenterVisible = true;
  form: FormGroup;
  isLoading: boolean;
  login: string;
  steps: WizardStep[] = [
    new WizardStep(
      'selectProducts',
      'Select Your Products'
    ),
    new WizardStep(
      'configureOrder',
      'Configure Your Order'
    ),
    new WizardStep(
      'selectShippingAddress',
      'Select Your Shipping Address'
    ),
    new WizardStep(
      'addEditShippingAddress',
      'Select Your Shipping Address'
    ),
    new WizardStep(
      'selectPaymentMethod',
      'Select A Payment Method'
    ),
    new WizardStep(
      'addEditPaymentMethod',
      'Add A Payment Method'
    ),
    new WizardStep(
      'selectBillingAddress',
      'Select A Billing Address'
    ),
    new WizardStep(
      'addEditBillingAddress',
      'Select Your Billing Address'
    ),
    new WizardStep(
      'reviewYourOrderStep',
      'Review Your Order'
    )
  ];

  constructor(
    private arpOrderService: ArpOrderService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder) {
    this.currentStep = this.steps[0];
  }

  get dialogContentMaxHeight(): number {
    if (this.currentStep.stepName === 'addEditShippingAddress' ||
      this.currentStep.stepName === 'addEditBillingAddress' ||
      this.currentStep.stepName === 'addEditPaymentMethod'
    ) {
      return 630;
    } else {
      return window.innerHeight - 300;
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    this.arpOrderService.setAvailableProducts(null);
  }

  buildForm(): void {
    this.form = this.formBuilder.group({});
    this.form['submitted'] = false;
  }

  changeStepTitle(title): void {
    this.currentStep.title = title;
  }

  closeDialog(): void {
    this.onToggleWizardDialog.emit(false);
    this.currentStep = null;
  }

  // move to next wizard step
  goToNextStep(event: string): void {
    this.currentStep = this.steps.find(item =>
      item.stepName === event
    );
    this.centerDialog();
  }

  onSubmit(event): void {
    event.preventDefault();
    this.form['submitted'] = true;

    if (!this.form.pristine && this.form.valid && !this.isLoading) {
      this.isLoading = true;
    }
    this.isLoading = false;
  }

  /**
   * Needed to center dialog with new width
   * Workaround for issue: https://github.com/primefaces/primeng/issues/2795
   */
  private centerDialog(): void {
    this.dialogCenterVisible = false;
    setTimeout(() => {
      this.dialogCenterVisible = true;
      this.cdr.detectChanges();
    }, 0);
  }
}

class WizardStep {
  stepName: string;
  title: string;

  constructor(
    stepName: string,
    title: string
  ) {
    this.stepName = stepName;
    this.title = title;
  }
}

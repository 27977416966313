import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { ForgotPasswordStep } from "../../classes/forgot-password-step";
import { ForgotPasswordQueryParams } from "../../classes/forgot-password-query-params";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  public currentStep: ForgotPasswordStep;
  public memberId: string;
  private params: ForgotPasswordQueryParams = new ForgotPasswordQueryParams();
  private steps: ForgotPasswordStep[] = [
    {
      header: "RESET PASSWORD",
      text: "",
      stepName: "resetPassword",
      nextStepName: "checkEmail",
    },
    {
      header: "CHECK YOUR EMAIL",
      text:
        'We sent password reset instructions to your email address.Remember to check your junk or spam folder if you don"t see it.',
      stepName: "checkEmail",
      nextStepName: "createPassword",
    },
    {
      header: "CREATE PASSWORD",
      text:
        "Your password must be 6-11 characters long and contain only numbers, letters, and periods",
      stepName: "createPassword",
      nextStepName: "passwordUpdate",
    },
    {
      header: "PASSWORD UPDATED",
      text: "Your password has been updated.",
      stepName: "passwordUpdate",
      nextStepName: "",
    },
  ];
  public token: string;

  constructor(private route: ActivatedRoute) {
    this.detectStep();
  }

  ngOnInit() {}

  private detectStep(): void {
    this.route.queryParams.subscribe((params: ForgotPasswordQueryParams) => {
      if (params.reset && params.custid) {
        this.currentStep = this.steps[2]; // createPassword step
        this.memberId = params.custid;
        this.token = params.reset;
        return;
      }
      this.currentStep = this.steps[0]; // resetPassword step
    });
  }

  public goToStep(stepName: string): void {
    this.currentStep = this.steps.filter((step) => {
      return step.stepName === stepName;
    })[0];
  }
}

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';

import {Country} from '../classes/country';

@Injectable()
export class CountryService {

  constructor(private httpService: HttpService) {
  }

  getCountriesWithStates(): Observable<Country[]> {
    return this.httpService.get(`postalhelper/`, 'cart').pipe(map((response: { countries: Country[] }) => {
      /**
       * Add label property for primeng dropdowns
       */
      return response.countries.map(country => {
        country['label'] = country.displayName;
        country.states = country.states.map(state => {
          state['label'] = state.displayName;
          return state;
        });
        return country;
      });
    }));
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isLoading = true;
  constructor(private router: Router, public authService: AuthService) {}

  ngOnInit() {
    this.authService.loginChanged$.subscribe(() => {
      if (!this.authService.isLoggedIn()) {
        this.authService.openLoginModal();
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.authService.closeLoginModal();
      }
    });
  }
}

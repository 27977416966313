import { ClipboardModule } from "ngx-clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxMaskModule } from "ngx-mask";
import { TextMaskModule } from "angular2-text-mask";
import { ToastModule } from "primeng/toast";

import { SharedModule } from "../../modules/shared/shared.module";

// components
import { AccountBasicInfoComponent } from "./account-basic-info/account-basic-info.component";
import { AccountManagmentComponent } from "./account-managment.component";
import { AddressListComponent } from "./contact-information/address-list/address-list.component";
import { AddEditAddressComponent } from "./auto-replenishment-program/shared/add-edit-address/add-edit-address.component";
import { AddEditAddressFormComponent } from "./contact-information/address-list/add-edit-address-form/add-edit-address-form.component";
import { AddEditBillingAddressStepComponent } from "./auto-replenishment-program/arp-order-wizard/add-edit-billing-address-step/add-edit-billing-address-step.component";
import { AddEditPaymentMethodStepComponent } from "./auto-replenishment-program/arp-order-wizard/add-edit-payment-method-step/add-edit-payment-method-step.component";
import { AddEditShippingAddressStepComponent } from "./auto-replenishment-program/arp-order-wizard/add-edit-shipping-address-step/add-edit-shipping-address-step.component";
import { AddressManagementComponent } from "./auto-replenishment-program/arp-order-editing/address-management/address-management.component";
import { ArpOrderComponent } from "./auto-replenishment-program/arp-order/arp-order.component";
import { ArpOrderAddressViewComponent } from "../account-managment/auto-replenishment-program/shared/arp-order-personal-info/arp-order-address-view/arp-order-address-view.component";
import { ArpOrderCardViewComponent } from "../account-managment/auto-replenishment-program/shared/arp-order-personal-info/arp-order-card-view/arp-order-card-view.component";
import { ArpOrderEditingComponent } from "./auto-replenishment-program/arp-order-editing/arp-order-editing.component";
import { ArpOrderOptionComponent } from "../account-managment/auto-replenishment-program/shared/arp-order-option/arp-order-option.component";
import { ArpOrderProductsComponent } from "./auto-replenishment-program/shared/arp-order-products/arp-order-products.component";
import { ArpOrderPersonalInfoComponent } from "./../account-managment/auto-replenishment-program/shared/arp-order-personal-info/arp-order-personal-info.component";
import { ArpOrderWizardComponent } from "./auto-replenishment-program/arp-order-wizard/arp-order-wizard.component";
import { AutoReplenishmentProgramComponent } from "./auto-replenishment-program/auto-replenishment-program.component";
import { AvailableProductsComponent } from "./auto-replenishment-program/arp-order-wizard/select-products-step/available-products/available-products.component";
import { ContactInformationComponent } from "./contact-information/contact-information.component";
import { ConfigureOrderStepComponent } from "./auto-replenishment-program/arp-order-wizard/configure-order-step/configure-order-step.component";
import { CreditCardFormComponent } from "./auto-replenishment-program/arp-order-wizard/select-payment-method-step/credit-card-form/credit-card-form.component";
import { CvvHelpComponent } from "../../components/shared/cvv-help/cvv-help.component";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { PasswordComponent } from "./password/password.component";
import { PaymentMethodComponent } from "./payment-method/payment-method.component";
import { PaymentManagementComponent } from "./auto-replenishment-program/arp-order-editing/payment-management/payment-management.component";
import { ProductsManagementComponent } from "./auto-replenishment-program/arp-order-editing/products-management/products-management.component";
import { ReviewYourOrderStepComponent } from "./auto-replenishment-program/arp-order-wizard/review-your-order-step/review-your-order-step.component";
import { SelectBillingAddressStepComponent } from "./auto-replenishment-program/arp-order-wizard/select-billing-address-step/select-billing-address-step.component";
import { SelectProductsStepComponent } from "./auto-replenishment-program/arp-order-wizard/select-products-step/select-products-step.component";
import { SelectPaymentMethodStepComponent } from "./auto-replenishment-program/arp-order-wizard/select-payment-method-step/select-payment-method-step.component";
import { SelectShippingAddressStepComponent } from "./auto-replenishment-program/arp-order-wizard/select-shipping-address-step/select-shipping-address-step.component";
import { ReferAFriendComponent } from "./refer-a-friend/refer-a-friend.component";
import { SubscriptionComponent } from "./subscription/subscription.component";
import { PremierMembershipPlusComponent } from './premier-membership-plus/premier-membership-plus.component';
import { WecareComponent } from './wecare/wecare.component';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    NgxMaskModule,
    SharedModule,
    TextMaskModule,
    ToastModule,
  ],
  declarations: [
    AccountBasicInfoComponent,
    AccountManagmentComponent,
    AddressListComponent,
    AddEditAddressComponent,
    AddEditAddressFormComponent,
    AddressManagementComponent,
    ArpOrderComponent,
    ArpOrderAddressViewComponent,
    ArpOrderCardViewComponent,
    ArpOrderEditingComponent,
    ArpOrderOptionComponent,
    AddEditBillingAddressStepComponent,
    AddEditPaymentMethodStepComponent,
    AddEditShippingAddressStepComponent,
    ArpOrderProductsComponent,
    ArpOrderPersonalInfoComponent,
    ArpOrderWizardComponent,
    AutoReplenishmentProgramComponent,
    AvailableProductsComponent,
    ContactInformationComponent,
    ConfigureOrderStepComponent,
    CreditCardFormComponent,
    CvvHelpComponent,
    OrderHistoryComponent,
    PasswordComponent,
    PaymentMethodComponent,
    PaymentManagementComponent,
    PaymentManagementComponent,
    ReferAFriendComponent,
    ProductsManagementComponent,
    ReviewYourOrderStepComponent,
    SelectBillingAddressStepComponent,
    SelectProductsStepComponent,
    SelectPaymentMethodStepComponent,
    SelectShippingAddressStepComponent,
    SubscriptionComponent,
    PremierMembershipPlusComponent,
    WecareComponent,
  ],
})
export class AccountManagmentModule {}

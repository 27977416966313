import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PrimengModule} from '../primeng/primeng.module';
import {SafeHtmlPipe} from './safe-html.pipe';
import {SafeResourceUrlPipe} from './safe-resource-url.pipe';
import {ArpOrderOptionsPipe} from './../../pipes/arp-order-options';
import {StateNamePipe} from './../../pipes/state-name.pipe';
import {InputUppercaseDirective} from './../../directives/input-uppercase.directive';
import {HttpClientModule} from '@angular/common/http';
import {AppSpinnerModule} from '../spinner/spinner.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    PrimengModule,
    ReactiveFormsModule,
    AppSpinnerModule
  ],
  declarations: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    ArpOrderOptionsPipe,
    StateNamePipe,
    InputUppercaseDirective
  ],
  exports: [
    AppSpinnerModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    PrimengModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    ArpOrderOptionsPipe,
    StateNamePipe,
    InputUppercaseDirective
  ]
})
export class SharedModule {
}

import { AuthService } from './auth.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {UserInfo} from '../classes/user-info';

@Injectable()
export class ProfileService {
  userToken: string;

  constructor(private httpService: HttpService, private authService: AuthService) {
    if (this.authService.getCurrentUser()) {
      this.userToken = this.authService.getToken();
    }
  }

  /**
   * Get profile info from API
   */
  retrieveProfile(memberId: string): Observable<UserInfo> {
    return this.httpService.get(`profile/${memberId}`, 'account', this.userToken);
  }
}

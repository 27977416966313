import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";

import { LogOutService } from "./log-out.service";
import { ConfigService } from "./config.service";

@Injectable()
export class HttpService {
  apiAccountUrl: string;
  apiCartUrl: string;
  apiGlobalUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private logOutService: LogOutService
  ) {
    this.decideBaseUrl();
  }

  /**
   * Custom wrappers for angular http service methods (get, post, put etc.)
   */
  public get(
    reqUrl: string,
    apiName: string,
    token: string = "",
    contentType: string = "application/json"
  ): Observable<any> {
    const url = this.getApiUrl(apiName) + reqUrl;
    const reqOptions = this.getRequestOptions(contentType, `bearer ${token}`);
    return this.http
      .get(url, reqOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public post(
    reqUrl: string,
    apiName: string,
    body: any,
    token: string = "",
    contentType: string = "application/json"
  ): Observable<any> {
    const url = this.getApiUrl(apiName) + reqUrl;

    const reqOptions = this.getRequestOptions(contentType, `bearer ${token}`);
    if (contentType === "application/json") {
      body = JSON.stringify(body);
    }

    return this.http
      .post(url, body, reqOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public put(
    reqUrl: string,
    apiName: string,
    body: any,
    token: string = "",
    contentType: string = "application/json"
  ): Observable<any> {
    const url = this.getApiUrl(apiName) + reqUrl;
    const reqOptions = this.getRequestOptions(contentType, `bearer ${token}`);
    if (contentType === "application/json") {
      body = JSON.stringify(body);
    }
    return this.http
      .put(url, body, reqOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Form Headers object for request
   */
  private getHeaders(
    contentType: string = "application/json",
    authorization: string
  ) {
    return new HttpHeaders({
      Accept: "application/json",
      "Content-Type": contentType,
      Authorization: authorization,
    });
  }

  /**
   * Form Request Options object for request
   */
  private getRequestOptions(contentType?: string, authorization?: string) {
    return { headers: this.getHeaders(contentType, authorization) };
  }

  /**
   * Default error handling for http request
   */
  private handleError(error: any) {
    if (error.status === 401 && error.statusText === "Unauthorized") {
      // this.logOutService.setLogOutSubject(true);
    }
    return observableThrowError(error);
  }

  private decideBaseUrl() {
    const apiUrlData = this.configService?.getConfiguration()?.apiUrlData;
    if (apiUrlData) {
      this.apiAccountUrl = apiUrlData.host + apiUrlData.routes.account;
      this.apiCartUrl = apiUrlData.host + apiUrlData.routes.cart;
      this.apiGlobalUrl = apiUrlData.host + apiUrlData.routes.global;
    }
  }

  private getApiUrl(value: string): string {
    //incase contructor failed to load the apiUrlData;
    this.decideBaseUrl();

    switch (value) {
      case "account":
        return this.apiAccountUrl;
      case "cart":
        return this.apiCartUrl;
      case "global":
        return this.apiGlobalUrl;
      case "wellness":
        return this.configService.getConfiguration().wellness;
      case "trivita_ocenture":
        return this.configService.getConfiguration().trivita_ocenture;
      case "cart_trivita":
        return this.configService.getConfiguration().cart_trivita;
      default:
        return this.configService.getConfiguration().subscriptionUrl;
    }
  }
}

import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { AffiliateService } from "../../../services/affiliate.service";
import { UserInfo } from "../../../classes/user-info";
import { Dashboard } from "../../../classes/dashboard-info";


@Component({
  selector: 'app-account-basic-info',
  templateUrl: './account-basic-info.component.html',
  styleUrls: ['./account-basic-info.component.scss']
})
export class AccountBasicInfoComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() user: UserInfo;
  @Output() linkToPanelClicked = new EventEmitter(null);

  dashboardDetails: Dashboard;
  pointsSummaryDialogVisible = false;
  routeFragmentSubscription: Subscription;
  typeOfMember: string;

  constructor(private route: ActivatedRoute, private affiliateService: AffiliateService) {
  }

  ngOnInit() {
    this.getDashboardDetails();
    this.defineTypeOfMember();
  }

  /**
   * Hook used for anchor scrolling
   * source: https://github.com/angular/angular/issues/6595#issuecomment-275500605
   *
   * Start
   */
  ngAfterViewChecked() {
    this.routeFragmentSubscription = this.route.fragment
      .subscribe(fragment => {
        if (fragment) {
          const element = document.getElementById(fragment);
          if (element) {
            element.scrollIntoView();
            window.location.hash = '';
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.routeFragmentSubscription) {
      this.routeFragmentSubscription.unsubscribe();
    }
  }

  /**
   * End
   */

  // defining type of member of current user
  defineTypeOfMember(): void {
    if (this.user.isItbo === 'true' || this.user.isItbo === true) {
      this.typeOfMember = 'Itbo';
    } else if (this.user.isPremierMember === 'true' || this.user.isPremierMember === true) {
      this.typeOfMember = 'Premier Member';
    } else {
      this.typeOfMember = 'Member';
    }
  }

  // get info to displaing in  account bacis info view
  getDashboardDetails(): void {
    this.affiliateService.getDashboardInfo(this.user.memberId).subscribe(response => {
      this.dashboardDetails = response.dashboard;
    });
  }
}

import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessageService } from "primeng/api";

import { AffiliateService } from "../../../services/affiliate.service";

@Component({
  selector: "app-tutorials",
  templateUrl: "./tutorials.component.html",
  styleUrls: ["./tutorials.component.scss"],
})
export class TutorialsComponent implements OnInit, OnDestroy {
  @Input() panelCollapsed: boolean;

  public isLoading: false;
  public tutorials = [];

  private destroy$ = new Subject();

  constructor(
    private affiliateService: AffiliateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getTutorials();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get tutorials from API using service
  private getTutorials(): void {
    this.affiliateService
      .getTutorials()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.tutorials = response;
        },
        ({ error: { error_description } }) => {
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      );
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerModule} from 'angular2-spinner/dist';
import {ProgressSpinnerComponent} from './spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    SpinnerModule
  ],
  declarations: [
    ProgressSpinnerComponent
  ],
  exports: [
    ProgressSpinnerComponent
  ]
})
export class AppSpinnerModule {
}

import { AuthService } from './auth.service';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';

import {AffiliateMessage} from '../classes/affiliate-message';
import {Banking, BankingInfoResponse} from '../classes/banking-info-response';
import {Business, BusinessInfoResponse} from '../classes/business-info-response';
import {Customer} from '../classes/customer';
import {DashboardInfo} from '../classes/dashboard-info';
import {IEmailMessage} from '../classes/email-message';
import {IHtmlTemplate} from '../classes/html-template';
import {IMediaLibraryVideo} from '../classes/media-library-video';
import {Payment} from '../classes/payment';

@Injectable()
export class AffiliateService {
  contentType: string;

  constructor(private httpService: HttpService, private authService: AuthService) {
    this.contentType = 'application/json';
  }

  get userToken(): string {
    return this.authService.getToken();
  }

  // Get affiliate 'contacts' info
  getContacts(
    memberId: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string
  ): Observable<{ customers: Customer[] }> {
    return this.httpService.get
    (`contacts/${memberId}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`,
      'account',
      this.userToken);
  }

  // Get affiliate 'dashboard' info
  getDashboardInfo(memberId: string): Observable<DashboardInfo> {
    return this.httpService.get(`dashboard/${memberId}`, 'account', this.userToken);
  }

  // Get affiliate 'banking' or 'business' info
  getInfo(typeName: string, memberId: string): Observable<BankingInfoResponse & BusinessInfoResponse> {
    return this.httpService.get(`${typeName}/${memberId}`, 'account', this.userToken);
  }

  // Get affiliate 'messages' info
  getMessages(): Observable<{ messages: AffiliateMessage[] }> {
    return this.httpService.get(`messages/`, 'account', this.userToken);
  }

  // Get affiliate 'payments' info
  getPayments(memberId: string): Observable<{ payments: Payment[] }> {
    return this.httpService.get(`payments/${memberId}`, 'account', this.userToken);
  }

  // Get affiliate printed materials
  getPrintedMaterials(): Observable<IHtmlTemplate[]> {
    return this.httpService.get(`materials/`, 'account');
  }

  // Get affiliate tutorials
  getTutorials(): Observable<IHtmlTemplate[]> {
    return this.httpService.get(`tutorials/`, 'account');
  }

  // Get affiliate videos
  getVideos(memberId: string): Observable<IMediaLibraryVideo[]> {
    return this.httpService.get(`videos/`, 'account').pipe(
      map((videos: IMediaLibraryVideo[]) => {
        videos.forEach(video => {
          video.ShareButtonUrl = video.ShareButtonUrl.replace('{0}', memberId);
          return video;
        });
        return videos;
      }));
  }

  // Save affiliate 'banking' or 'business' info
  saveInfo(typeName: string, memberId: string, info: Banking & Business): Observable<any> {
    return this.httpService.post(`${typeName}/${memberId}`, 'account', info, this.userToken);
  }

  // Send email message to API
  sendEmailMessage(body: IEmailMessage): Observable<any> {
    return this.httpService.post(`videos/`, 'account', body);
  }
}

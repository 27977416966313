import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";

@Injectable()
export class OrderHistoryService {
  constructor(
    private httpService: HttpService,
    private authService: AuthService
  ) {}

  get userToken(): string {
    return this.authService.getToken();
  }

  getOrderHistory(
    memberId: number,
    startDate: string = "",
    endDate: string = ""
  ): Observable<any> {
    return this.httpService.get(
      `orderhistory/${memberId}?startDate=${startDate}&endDate=${endDate}`,
      "account",
      this.userToken
    );
  }

  getOrderHistoryById(memberId: number, orderId: number): Observable<any> {
    return this.httpService.get(
      `orderhistory/${memberId}?orderId=${orderId}`,
      "account",
      this.userToken
    );
  }
}

import { AuthService } from './../../../../services/auth.service';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";

import { AddressService } from "../../../../services/address.service";
import { CountryService } from "../../../../services/country.service";
import { ContactInfoService } from "../../../../services/contact-info.service";
import { GoogleAnalyticsService } from "../../../../services/google-analytics.service";
import { WizardHelperService } from "../../../../services/wizard-helper.service";
import { Address } from "../../../../classes/arp-order";
import { Country } from "../../../../classes/country";
import { UserInfo } from "../../../../classes/user-info";

@Component({
  selector: "app-address-list",
  templateUrl: "./address-list.component.html",
  styleUrls: ["./address-list.component.scss"],
})
export class AddressListComponent implements OnInit, OnDestroy {
  @Input() country: Country;
  @Input() memberId: number;
  @Input() form: FormGroup;
  @Input() user: UserInfo;
  @Input() isEditingBillingAddress: boolean;
  @Output() onCloseDialog = new EventEmitter<number>();
  @Output() onSelectAddress = new EventEmitter<any>();
  @Output() onShowNotification = new EventEmitter<any>();

  private changeDetectionInterval: any;
  private notChangedBody: any;
  private sub: Subscription;

  public addressItems: { address: Address; checked: boolean }[];
  public addressList: Address[];
  public dialogContentMaxWidth: number = window.innerWidth - 300;
  public dialogCenterVisible = true; // needed to center dialog, issue: https://github.com/primefaces/primeng/issues/2795
  public editableAddress: any;
  public isAddingEditingAddress = false;
  public isLoading = false;
  public spinnerText: string;
  public title = "Address Management";

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(
    private addressService: AddressService,
    private contactInfoService: ContactInfoService,
    private countryService: CountryService,
    private gaService: GoogleAnalyticsService,
    private wizardHelperService: WizardHelperService,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    this.changeDetectionInterval = setInterval(() => {
      ref.markForCheck();
    }, 50);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    clearInterval(this.changeDetectionInterval);
  }

  ngOnInit() {
    this.getAddresses();
  }

  // handler toggle btn event
  public changeCheckedAddress(checked, address): void {
    this.notChangedBody = this.form.value;
    if (checked) {
      this.addressItems.map((item) => {
        item.checked = false;
        if (item.address.addressId === address.address.addressId) {
          item.checked = true;
          if (this.isEditingBillingAddress) {
            this.form.controls.billingAddressId.setValue(
              address.address.addressId
            );
          } else {
            this.form.controls.shippingAddressId.setValue(
              address.address.addressId
            );
          }
          this.sendUpdatedInfo(address.address);
        }
      });
    } else {
      this.addressItems.map((item) => {
        item.checked = false;
        const defaultAddress = !this.isEditingBillingAddress
          ? this.addressList.find(
              (adressItem) => adressItem.isDefaultShippingAddress === true
            )
          : this.addressList.find(
              (adressItem) => adressItem.isDefaultBillingAddress === true
            );

        if (item.address.addressId === defaultAddress.addressId) {
          item.checked = true;
          if (this.isEditingBillingAddress) {
            this.form.controls.billingAddressId.setValue(
              defaultAddress.addressId
            );
          } else {
            this.form.controls.shippingAddressId.setValue(
              defaultAddress.addressId
            );
          }
          this.sendUpdatedInfo(address.address);
        }
      });
    }
  }

  /**
   * Show form to adding or editing address
   */
  public addEditAddress(address = null): void {
    this.title = address ? "Editing Address" : "Adding Address";
    this.isAddingEditingAddress = true;
    this.editableAddress = address;
    this.centerDialog();
  }

  /**
   * Hide form to adding or editing address
   */
  public cancelAdditingAddress(): void {
    this.isAddingEditingAddress = false;
    this.title = "Address Management";
    this.editableAddress = null;
    this.centerDialog();
  }

  /**
   * Hide p-dialog
   */
  public closeDialog(): void {
    // this.display = false;
    this.isAddingEditingAddress = false;
    this.onCloseDialog.emit(null);
  }

  // get all addresses from api and set them to subject
  private getAddresses(): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.sub = this.wizardHelperService.addressListSubject.subscribe(
      (addressList) => {
        if (!addressList.length) {
          this.addressService.getAllAddresses(this.memberId).subscribe(
            (response) => {
              this.wizardHelperService.setAddressList(response.addresses);
              this.addressList = response.addresses;
              this.transformAddresses(this.addressList);
              this.isLoading = false;
              this.spinnerText = null;
            },
            ({ error: { error_description }, status }) => {
              if ([401, 403].includes(status)) {
                this.authService.logOut();
              } else {
                this.messageService.add({
                  severity: "error",
                  summary: error_description,
                });
              }
            }
          );
        } else {
          this.addressList = addressList;
          this.transformAddresses(this.addressList);
          this.isLoading = false;
          this.spinnerText = null;
        }
      }
    );
  }

  /**
   * Remove selected address and update address list
   */
  public removeAddress(addressId: string): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.addressService.removeAddress(this.user.memberId, addressId).subscribe(
      (response) => {
        this.wizardHelperService.setAddressList(response.addresses);
        this.addressList = response.addresses;
        this.isLoading = false;
        this.spinnerText = null;
        this.messageService.add({
          severity: "success",
          summary: "Address was removed",
        });
      },
      ({ error: { error_description } }) => {
        this.isLoading = false;
        this.spinnerText = null;
        this.messageService.add({
          severity: "error",
          summary: error_description,
        });
      }
    );
  }

  /**
   * Emit selecting new address event to parent
   */
  private selectThisAddress(address: Address): void {
    this.isEditingBillingAddress
      ? (address.isDefaultBillingAddress = true)
      : (address.isDefaultShippingAddress = true);
    this.updateAddressList(address);
  }

  /**
   * Send new address to service and update address list
   */
  public updateAddressList(address: Address): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.addressService.updateAddressList(address).subscribe(
      (response) => {
        this.wizardHelperService.setAddressList(response.addresses);
        this.addressList = response.addresses;
        this.isAddingEditingAddress = false;
        this.centerDialog();
        this.isLoading = false;
        this.spinnerText = null;

        // Google Analytics events
        if (address.addressId) {
          this.gaService.sendGoogleAnalyticsEvent("updateAddress");
          this.onShowNotification.emit({
            severity: "success",
            message: "Contact info was updated.",
          });
          this.closeDialog();
        } else {
          this.messageService.add({
            severity: "success",
            summary: "Address list was updated.",
          });
          this.gaService.sendGoogleAnalyticsEvent("addAddress");
        }

        this.onSelectAddress.emit(true);
      },
      ({ error: { error_description, Message } }) => {
        this.isLoading = false;
        this.spinnerText = null;

        if (Message) {
          this.messageService.add({ severity: "error", summary: Message });
        } else {
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      }
    );
  }

  /**
   * Update component addresses
   * @param addresses - addresses list
   */
  private transformAddresses(addresses: any): void {
    if (!this.isEditingBillingAddress) {
      const addressItems = addresses.map((address) => {
        if (address.addressId === this.form.controls.shippingAddressId.value) {
          return { address, checked: true };
        } else {
          return { address, checked: false };
        }
      });
      this.addressItems = addressItems.slice();
    } else {
      const addressItems = addresses.map((address) => {
        if (address.addressId === this.form.controls.billingAddressId.value) {
          return { address, checked: true };
        } else {
          return { address, checked: false };
        }
      });
      this.addressItems = addressItems.slice();
    }
  }

  // send updated info to service
  private sendUpdatedInfo(address: Address): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.contactInfoService.updateContactInfo(this.form.value).subscribe(
      (response) => {
        this.selectThisAddress(address);
        this.isLoading = false;
        this.spinnerText = null;
        this.messageService.add({
          severity: "success",
          summary: "Address was changed",
        });
      },
      ({ error: { error_description } }) => {
        this.isLoading = false;
        this.spinnerText = null;

        this.messageService.add({
          severity: "error",
          summary: error_description,
        });

        this.form.controls.billingAddressId.setValue(
          this.notChangedBody.billingAddressId
        );
        this.form.controls.shippingAddressId.setValue(
          this.notChangedBody.shippingAddressId
        );
        this.transformAddresses(this.addressList);
      }
    );
  }

  /**
   * Needed to center dialog with new width
   * Workaround for issue: https://github.com/primefaces/primeng/issues/2795
   */
  private centerDialog(): void {
    this.dialogCenterVisible = false;
    setTimeout(() => {
      this.dialogCenterVisible = true;
    }, 0);
  }
}

import { AuthService } from './../../../services/auth.service';
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { UserInfo } from "../../../classes/user-info";
import { DashboardInfo } from "../../../classes/dashboard-info";
import { AffiliateService } from "../../../services/affiliate.service";

@Component({
  selector: "app-affiliate-basic-info",
  templateUrl: "./affiliate-basic-info.component.html",
  styleUrls: ["./affiliate-basic-info.component.scss"],
})
export class AffiliateBasicInfoComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserInfo = new UserInfo();

  public dashboardInfo: DashboardInfo;
  public isLoading = false;

  private destroy$ = new Subject();

  constructor(
    private affiliateService: AffiliateService,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getDashboardInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get dashboard information from service
  private getDashboardInfo(): void {
    this.isLoading = true;
    this.affiliateService
      .getDashboardInfo(this.currentUser.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (dashboardInfo: DashboardInfo) => {
          this.dashboardInfo = dashboardInfo;
          this.isLoading = false;
        },
        ({ error: { error_description }, status }) => {
          this.isLoading = false;
          if ([401, 403].includes(status)) {
            this.authService.logOut();
          } else {
            this.messageService.add({
              severity: "error",
              summary: error_description,
            });
          }
        }
      );
  }
}

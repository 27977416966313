import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TextMaskModule } from "angular2-text-mask";

import { SharedModule } from "../../modules/shared/shared.module";
import { AccountManagmentModule } from "../account-managment/account-managment.module";
import { AffiliateOfficeModule } from "../affiliate-office/affiliate-office.module";
import { mainRoutes } from "./main.routes";
import { MainComponent } from "./main.component";
import { SubscriptionGuard } from "./subscription.guard";
import { MainGuard } from "./main.guard";

@NgModule({
  imports: [
    AccountManagmentModule,
    AffiliateOfficeModule,
    CommonModule,
    RouterModule.forChild(mainRoutes),
    TextMaskModule,
    SharedModule,
  ],
  declarations: [MainComponent],
  providers: [MainGuard, SubscriptionGuard],
})
export class MainModule {}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectItem } from "primeng";
import { MessageService } from "primeng/api";

import { ArpOrderService } from "../../../../../services/arp-order.service";
import { GoogleAnalyticsService } from "../../../../../services/google-analytics.service";
import { PaymentService } from "../../../../../services/payment.service";
import { WizardHelperService } from "../../../../../services/wizard-helper.service";

@Component({
  selector: "app-payment-management",
  templateUrl: "./payment-management.component.html",
  styleUrls: ["./payment-management.component.scss"],
})
export class PaymentManagementComponent {
  @Input() form: FormGroup;
  @Output() onClosePaymentManagement: EventEmitter<string> = new EventEmitter(
    null
  );
  @Output() onShowNotification = new EventEmitter<any>();

  public creditCardsTypes: SelectItem[] = [];
  public isLoading = false;
  public spinnerText: string;
  public dialogContentMaxWidth: number = window.innerWidth - 300;

  /**
   * Window resize handler for opened dialog
   */
  @HostListener("window:resize", ["$event"]) onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(
    private arpOrderService: ArpOrderService,
    private gaService: GoogleAnalyticsService,
    private paymentService: PaymentService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {
    this.getCreditCards();
  }

  // send updated order to service and update general order list
  sendUpdatedOrder(): void {
    this.loading();

    this.arpOrderService.editArpOrder(this.form.value).subscribe(
      (response) => {
        this.wizardHelperService.setOrderList(response.arpOrders); // update general order list

        this.gaService.sendGoogleAnalyticsEvent("changeArpCreditCard");
        this.onShowNotification.emit({
          error: false,
          message: "Payment was changed.",
        });
        this.onClosePaymentManagement.emit();

        this.loading(false);
      },
      ({ error: { error_description, error } }) => {
        if (error === "AEC0053") {
          this.messageService.add({
            severity: "error",
            summary:
              "There was an error processing the credit card provided. Please update and try again.",
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }

        this.loading(false);
      }
    );
  }

  private getCreditCards(): void {
    this.paymentService.getCardTypes().subscribe(
      (response) => {
        const creditCardsTypes = response.countries[2].cardTypes;
        for (let i = 0; i < creditCardsTypes.length; i++) {
          this.creditCardsTypes.push({
            value: creditCardsTypes[i].value.toString(),
            label: creditCardsTypes[i].displayName,
          });
        }
      },
      ({ error: { error_description } }) => {
        this.messageService.add({
          severity: "error",
          summary: error_description,
        });
      }
    );
  }

  private loading(enable: boolean = true): void {
    if (enable) {
      this.isLoading = true;
      this.spinnerText = "Processing your request...";
    } else {
      this.isLoading = false;
      this.spinnerText = null;
    }
  }
}

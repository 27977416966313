import { AuthService } from './../../../services/auth.service';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";

import { AddressService } from "./../../../services/address.service";
import {
  ArpOrderService,
  IntervalOptions,
} from "../../../services/arp-order.service";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { WizardHelperService } from "./../../../services/wizard-helper.service";
import { ArpOrder } from "../../../classes/arp-order";
import { Country } from "../../../classes/country";
import { UserInfo } from "../../../classes/user-info";

@Component({
  selector: "app-auto-replenishment-program",
  templateUrl: "./auto-replenishment-program.component.html",
  styleUrls: ["./auto-replenishment-program.component.scss"],
})
export class AutoReplenishmentProgramComponent implements OnInit, OnDestroy {
  @Input() country: Country;
  @Input() panelCollapsed: boolean;
  @Input() user: UserInfo;

  public isLoading: boolean;
  public orderList: ArpOrder[];
  public wizardDialogIsOpen: boolean;
  public spinnerText: string;
  public arpConfiguration: IntervalOptions;

  private sub: Subscription;

  constructor(
    private addressService: AddressService,
    private arpOrderService: ArpOrderService,
    private gaService: GoogleAnalyticsService,
    private authService: AuthService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getConfigurationOptions();
    this.getArpOrders();
    this.getAddresses();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // get order list for current user
  private getArpOrders(): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.arpOrderService.getArpOrders(this.user.memberId).subscribe(
      ({ arpOrders }) => {
        this.orderList = arpOrders;
        this.wizardHelperService.setOrderList(arpOrders);
        this.sub = this.wizardHelperService.orderListSubject.subscribe(
          (orders) => {
            this.orderList = orders;
          }
        );
        this.isLoading = false;
        this.spinnerText = null;
      },
      ({ error: { error_description }, status }) => {
        this.isLoading = false;
        this.spinnerText = null;
        if ([401, 403].includes(status)) {
          this.authService.logOut();
        } else {
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      }
    );
  }

  // get all addresses from api and set them to subject
  private getAddresses(): void {
    this.addressService.getAllAddresses(this.user.memberId).subscribe(
      ({ addresses }) => {
        this.wizardHelperService.setAddressList(addresses);
      },
      ({ error: { error_description }, status }) => {
        if ([401, 403].includes(status)) {
          this.authService.logOut();
        } else {
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      }
    );
  }

  // get arp configuration options from service
  private getConfigurationOptions(): void {
    if (this.arpOrderService.arpOrderOptions) {
      this.arpConfiguration = this.arpOrderService.arpOrderOptions;
    } else {
      this.arpOrderService.setConfigurationOptions().subscribe((response) => {
        this.arpConfiguration = response;
      });
    }
  }

  // update order list
  public onAddOrderHandler(orders: ArpOrder[]): void {
    if (orders === null) {
      return;
    } else {
      this.orderList = orders;
    }
  }

  /**
   * Arp order panel toggle handler
   * @param event - dom event object
   */
  public onPanelToggle({ collapsed }): void {
    if (!collapsed) {
      this.gaService.sendGoogleAnalyticsEvent("arpOpen");
    }
  }
}

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {
  CalendarModule,
  CarouselModule,
  DialogModule,
  DropdownModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  SelectButtonModule,
  SharedModule,
  TabViewModule,
  ToggleButtonModule,
  TooltipModule
} from 'primeng';
import {TableModule} from 'primeng/table';


const PRIMENG_MODULES = [
  CalendarModule,
  CarouselModule,
  DialogModule,
  DropdownModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  TabViewModule,
  SharedModule,
  SelectButtonModule,
  ToggleButtonModule,
  TooltipModule,
  TableModule
];

// @ts-ignore
@NgModule({
  exports: PRIMENG_MODULES,
  imports: [...PRIMENG_MODULES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PrimengModule {
}

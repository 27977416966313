import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MessageService } from "primeng/api";

import { ArpOrder } from "../../../../classes/arp-order";
import { ArpOrderService } from "../../../../services/arp-order.service";
import { GoogleAnalyticsService } from "../../../../services/google-analytics.service";
import { WizardHelperService } from "../../../../services/wizard-helper.service";
import { Country } from "../../../../classes/country";
import { UserInfo } from "../../../../classes/user-info";

@Component({
  selector: "app-arp-order",
  templateUrl: "./arp-order.component.html",
  styleUrls: ["./arp-order.component.scss"],
})
export class ArpOrderComponent {
  @Input() country: Country;
  @Input() user: UserInfo;
  @Input() orderList: ArpOrder[];

  @Output() onToggleWizardDialog = new EventEmitter<boolean>();

  private skippingOrderId: number;
  private cancelingOrderId: number;

  public editArpOrderForm: FormGroup;
  public editableOrder: ArpOrder;
  public isCreditCardError = false;
  public isSkippingConfirmation = false;
  public isCancelingConfirmation = false;
  public isProductManagement = false;
  public isPaymentManagement = false;
  public orderEditingDialogIsOpen: boolean;
  public productsListMode = "view";
  public typeOfAddress: string;
  public isLoading: boolean;
  public spinnerText: string;

  constructor(
    private arpOrderService: ArpOrderService,
    private gaService: GoogleAnalyticsService,
    private fb: FormBuilder,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  public onAddOrderClick(): void {
    this.onToggleWizardDialog.emit(true);
  }

  /**
   * Cancel arp order by id
   * @param orderId - order id
   */
  public cancelOrder(): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";

    // find index of canceled arp order
    const indexOfCanceledOrder = this.orderList.findIndex((item) => {
      return item.orderId === this.cancelingOrderId;
    });
    this.arpOrderService
      .cancelArpOrder(this.user.memberId, this.cancelingOrderId)
      .subscribe(
        (response) => {
          this.orderList.splice(indexOfCanceledOrder, 1);
          this.wizardHelperService.setOrderList(this.orderList);
          this.isLoading = false;
          this.spinnerText = null;
          this.cancelingOrderId = null;
          this.isCancelingConfirmation = false;
          this.messageService.add({
            severity: "success",
            summary: "Order was canceled",
          });
        },
        ({ error: { error_description } }) => {
          this.isLoading = false;
          this.spinnerText = null;
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      );
  }

  // close confirmation dialog
  public closeConfirmationDialog(): void {
    this.isSkippingConfirmation = false;
    this.isCancelingConfirmation = false;
    this.skippingOrderId = null;
    this.cancelingOrderId = null;
  }

  // show canceling confirmation dialog
  public isCancelingConfirmationShow(orderId): void {
    this.isCancelingConfirmation = true;
    this.cancelingOrderId = orderId;
  }

  // show skipping confirmation dialog
  public isSkippingConfirmationShow(orderId): void {
    this.isSkippingConfirmation = true;
    this.skippingOrderId = orderId;
  }

  /**
   * Open address management dialog
   * @param editingOrder - order object
   */
  public openAddressManagement({ order, typeOfAddress }): void {
    this.resetArpOrderEditing();
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = order;
    this.typeOfAddress = typeOfAddress;
  }

  public openProductManagement(editingOrder): void {
    this.typeOfAddress = null;
    this.isProductManagement = true;
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = editingOrder;
  }

  /**
   * Open address management dialog
   * @param editingOrder - order object
   */
  public openPaymentManagement(editingOrder: ArpOrder): void {
    this.resetArpOrderEditing();
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = editingOrder;
    this.isPaymentManagement = true;
  }

  /**
   * Open order options editing form
   * @param order - order object
   */
  public openEditOrderOptions(order: ArpOrder): void {
    this.editableOrder = order;
    this.productsListMode = "edit";
    this.buildForm();
  }

  private buildForm(): void {
    this.editArpOrderForm = this.fb.group({
      memberId: this.editableOrder.memberId,
      orderId: this.editableOrder.orderId,
      countryId: this.editableOrder.countryId,
      products: this.fb.array(
        this.editableOrder.products.map((product) => this.fb.group(product))
      ),
      dayOfMonthToShip: this.editableOrder.dayOfMonthToShip,
      interval: this.editableOrder.interval,
      autoApplyVitaPoints: this.editableOrder.autoApplyVitaPoints,
      shippingAddressId: this.editableOrder.shippingAddress.addressId,
      billingAddressId: this.editableOrder.billingAddress.addressId,
      payment: this.editableOrder.payment,
      previewOnly: false,
    });
  }

  // update arp order
  public saveArpOrder(): void {
    this.isCreditCardError = false;
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.arpOrderService.editArpOrder(this.editArpOrderForm.value).subscribe(
      ({ arpOrders }: { arpOrders: ArpOrder[] }) => {
        this.sendGaEvent(arpOrders);
        this.wizardHelperService.setOrderList(arpOrders);
        this.resetArpOrderEditing();
        this.isLoading = false;
        this.spinnerText = null;
        this.messageService.add({
          severity: "success",
          summary: "Order was updated",
        });
      },
      ({ error: { error_description, error } }) => {
        if (error === "AEC0053") {
          this.isCreditCardError = true;
        } else {
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
        this.isLoading = false;
        this.spinnerText = null;
      }
    );
  }

  /**
   * Send event to GA with updated order Estimated Total
   * @param arpOrders - updated arp orders list
   */
  private sendGaEvent(arpOrders: ArpOrder[]): void {
    // Count estimated total sum and send it to Google Analytics
    const updatedOrder: ArpOrder = arpOrders.find(
      (order) => order.orderId === this.editArpOrderForm.value.orderId
    );

    if (updatedOrder) {
      const total =
        updatedOrder.estimatedShipping +
        updatedOrder.estimatedTax +
        updatedOrder.products
          .map((product) => product.price * product.quantity)
          .reduce((prevValue, currValue) => prevValue + currValue);

      this.gaService.sendGoogleAnalyticsEvent(
        "editArpOrder",
        Math.floor(total * 100) / 100
      );
    }
  }

  // cancel editing arp order
  public resetArpOrderEditing(): void {
    this.editableOrder = null;
    this.editArpOrderForm = null;
    this.productsListMode = "view";
  }

  // skip next order
  public skipNextOrder(): void {
    this.isLoading = true;
    this.spinnerText = "Processing your request...";
    this.arpOrderService
      .skipArpOrder(this.user.memberId, this.skippingOrderId)
      .subscribe(
        (response) => {
          this.arpOrderService.getArpOrders(this.user.memberId).subscribe(
            (orders) => {
              this.wizardHelperService.setOrderList(orders.arpOrders);
              this.isLoading = false;
              this.spinnerText = null;
              this.skippingOrderId = null;
              this.isSkippingConfirmation = false;
              this.messageService.add({
                severity: "success",
                summary: "Next shipment date was changed",
              });
            },
            ({ error: { error_description } }) => {
              this.isLoading = false;
              this.spinnerText = null;
              this.messageService.add({
                severity: "error",
                summary: error_description,
              });
            }
          );
        },
        ({ error: { error_description } }) => {
          this.isLoading = false;
          this.spinnerText = null;
          this.messageService.add({
            severity: "error",
            summary: error_description,
          });
        }
      );
  }

  // show success message after updating order
  public onProductListApply(order): void {
    this.editArpOrderForm.removeControl("products");
    this.editArpOrderForm.addControl(
      "products",
      this.fb.array(order.products.map((product) => this.fb.group(product)))
    );
  }

  public showNotification({ error, message }): void {
    if (error) {
      this.messageService.add({ severity: "error", summary: message });
    } else {
      this.messageService.add({ severity: "success", summary: message });
    }
  }

  public onCloseDialog(): void {
    this.isPaymentManagement = false;
    this.orderEditingDialogIsOpen = false;
    this.isProductManagement = false;
  }
}
